import React, { memo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import FlexBetween from './FlexBetween';

const StatBox = ({ title, value, increase, icon, description }) => {
    const theme = useTheme();
    return (
        <Box
            gridColumn='span 2'
            gridRow='span 1'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            p='1.25rem 1rem'
            flex='1 1 100%'
            backgroundColor='#e0e0e0'
            borderRadius='0.55rem'
        >
            <FlexBetween>
                <Typography
                    variant='h6'
                    sx={{ color: theme.palette.secondary[100] }}
                >
                    {title}
                </Typography>
                {icon}
            </FlexBetween>

            <Typography variant='h4' fontWeight='600' sx={{ color: '#4d547d' }}>
                {value}
            </Typography>
            <FlexBetween gap='1rem'>
                <Typography
                    variant='h5'
                    fontStyle='italic'
                    sx={{ color: '#070812' }}
                >
                    {increase}
                </Typography>
                <Typography>{description}</Typography>
            </FlexBetween>
        </Box>
    );
};

export default memo(StatBox);
