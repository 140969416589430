import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import categoryService from 'services/category/category.service';
import productService from 'services/product/product.service';
import FindProductByCategory from 'components/FindProductByCategory';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const renderStatusAccount = (props) => {
    const { value } = props;
    return (
        <>
            <Chip
                label={value ? 'Hoạt động' : 'Khóa'}
                color={value ? 'success' : 'error'}
            />
        </>
    );
};
const ListCourses = (props) => {
    const { open, handleClose, setDetailFlashSale, listProducts } = props;
    const [categorySelected, setCategorySelected] = useState('');
    const [listCategories, setListCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });

    const dispatch = useDispatch();

    const getProductByCategory = (type) => {
        productService.getAllProducts(type).then((res) => {
            let products = res.data.data.products;
            products = products?.filter(
                (product) => product.isPublish && product.originalPrice,
            );
            setProducts(products);
        });
    };

    const disabledButton = (startDate, endDate) => {
        if (!startDate || !endDate) {
            return false;
        }
        console.log(' start end', startDate, endDate);
        const current = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
        const result = current <= end && current >= start ? false : true;
        console.log('result', result);
        return result;
    };

    useEffect(() => {
        categoryService.getAllCategory().then((res) => {
            setListCategories(res.data.data);
            setCategorySelected(res.data.data[0].slug);
        });
    }, []);

    useEffect(() => {
        if (categorySelected) {
            console.log('categorySelected: ', categorySelected);
            getProductByCategory(categorySelected);
        }
    }, [categorySelected]);

    useEffect(() => {
        setSelectedProducts(listProducts);
    }, [listProducts]);

    const columns = [
        {
            field: 'title',
            headerName: 'Tên sản phẩmxxx',
            flex: 1,
        },
        {
            field: 'originalPrice',
            headerName: 'Gía bánxxx',
            flex: 0.5,
        },
        {
            field: 'createdBy',
            headerName: 'Người Tạo',
            flex: 0.4,
        },
        {
            field: 'isPublish',
            headerName: 'Trạng thái',
            flex: 0.4,
            renderCell: renderStatusAccount,
        },

        //
        {
            // field: "roles",
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => {
                const product = props.row;
                return (
                    <strong>
                        {!selectedProducts?.some(
                            (item) => item._id === product._id,
                        ) ? (
                            <Button
                                variant='contained'
                                size='small'
                                sx={{
                                    marginRight: '5px',
                                }}
                                onClick={() => {
                                    setSelectedProducts((preProducts) => [
                                        ...preProducts,
                                        {
                                            _id: product._id,
                                            title: product.title,
                                            slug: product.slug,
                                            originalPrice:
                                                product.originalPrice,
                                            priceSale: 0,
                                            percentage: 0,
                                        },
                                    ]);
                                }}
                                disabled={disabledButton(
                                    product?.flashSale?.startDate,
                                    product?.flashSale?.endDate,
                                )}
                            >
                                Chọn
                            </Button>
                        ) : (
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    setSelectedProducts((preProducts) =>
                                        preProducts.filter(
                                            (item) => item._id !== product._id,
                                        ),
                                    );
                                }}
                            >
                                Hủy Chọn
                            </Button>
                        )}
                    </strong>
                );
            },
        },
    ];

    const handleChange = (value) => {
        setCategorySelected(value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogContent>
                <DialogTitle>Chọn khóa học</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box m='1.5rem 2.5rem'>
                                <Box>
                                    <FindProductByCategory
                                        sx={{
                                            m: 1,
                                            minWidth: 200,
                                            maxHeight: 90,
                                            padding: '5px',
                                        }}
                                        handleChange={handleChange}
                                        categorySelected={categorySelected}
                                        listCategories={listCategories}
                                    />
                                    <Box>
                                        <i>
                                            * Các sản phẩm có giá lớn 0 mới được
                                            cho vào trong chương trình flash
                                            sale
                                        </i>
                                    </Box>
                                    <Box>
                                        <i>
                                            * Các sản phẩm chỉ được trong 1
                                            chương trình flash sale
                                        </i>
                                    </Box>
                                </Box>
                                <Box
                                    height='75vh'
                                    sx={{
                                        marginTop: '20px',
                                    }}
                                >
                                    <DataGrid
                                        columns={columns}
                                        rows={products}
                                        getRowId={(row) => row._id}
                                        pageSizeOptions={[20, 25]}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={
                                            setPaginationModel
                                        }
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setDetailFlashSale(selectedProducts);
                        handleClose();
                    }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    Chọn xong
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ListCourses;
