import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    RouteProps,
} from 'react-router-dom';
import { themeSettings } from 'theme';
import Layout from 'pages/layout';
import Dashboard from 'pages/Dashboard';
import Customers from 'pages/customers';
import Transactions from 'pages/transactions';
import Geography from 'pages/geography';
import Overview from 'pages/overview';
import Daily from 'pages/daily';
import Monthly from 'pages/monthly';
import Breakdown from 'pages/breakdown';
import Admin from 'pages/admin';
import Performance from 'pages/performance';
import Login from 'pages/login';
import Product from 'pages/Product';
import Users from 'pages/User';
import AlertMessage from 'components/AlertMessage';
import Orders from 'pages/orders';
import Category from 'pages/category';
import Voucher from 'pages/voucher';
import CodeForKit from 'pages/codeForKit';
import FlashSale from 'pages/flashSale';
import Slider from 'pages/slider';
import CategoryPost from 'pages/categoryPost';
import Post from 'pages/posts';
import VideoIntroBlog from 'pages/videoIntroBlog';
import Statistical from 'pages/statistical';

function App() {
    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    const { isLoggedIn } = useSelector((state) => state.auth);
    console.log('isLoggedIn ', isLoggedIn);

    function PrivateRoute({ children }) {
        const user = localStorage.getItem('user') ? true : false;
        return <>{user ? children : <Navigate to='/login' replace />}</>;
    }

    function AuthorRouter({ children }) {
        const user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null;
        const isAdmin =
            user?.roles.includes('admin') || user?.roles.includes('root')
                ? true
                : false;
        return <>{isAdmin ? children : <Navigate to='/posts' replace />}</>;
    }

    return (
        <div className='app'>
            <BrowserRouter>
                {/* <ThemeProvider theme={theme}> */}
                <AlertMessage />
                <Routes>
                    <Route
                        path='/'
                        element={<Navigate to='/login' replace />}
                    />
                    <Route path='/login' element={<Login />} />
                    <Route element={<Layout />}>
                        <Route path='/posts' element={<Post />} />
                        <Route
                            path='/video-intro-blog'
                            element={<VideoIntroBlog />}
                        />
                        <Route
                            path='/dashboard'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Dashboard />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/dashboard'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Dashboard />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/category'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Category />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/products'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Product />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/customers'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Customers />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path='/users'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Users />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/orders'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Orders />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/voucher'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Voucher />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/CodeForKit'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <CodeForKit />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/FlashSale'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <FlashSale />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/sliders'
                            element={
                                <PrivateRoute>
                                    <AuthorRouter>
                                        <Slider />
                                    </AuthorRouter>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path='/category-post'
                            element={
                                <AuthorRouter>
                                    <CategoryPost />
                                </AuthorRouter>
                            }
                        />
                        <Route
                            path='/statistical'
                            element={
                                <AuthorRouter>
                                    <Statistical />
                                </AuthorRouter>
                            }
                        />
                        {/* <Route
                            path='/transactions'
                            element={<Transactions />}
                        /> */}
                        {/* <Route path='/geography' element={<Geography />} />
                        <Route path='/overview' element={<Overview />} />
                        <Route path='/daily' element={<Daily />} />
                        <Route path='/monthly' element={<Monthly />} />
                        <Route path='/breakdown' element={<Breakdown />} />
                        <Route path='/admin' element={<Admin />} /> */}
                        {/* <Route path='/performance' element={<Performance />} /> */}
                    </Route>
                </Routes>
                {/* </ThemeProvider> */}
            </BrowserRouter>
        </div>
    );
}

export default App;
