import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import categoryServicePost from 'services/categoryPost/categoryPost.service';
import NewCategoryPost from './NewCategoryPost';
import FormatDate from 'components/FormatDate';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';

const CategoryPost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [detailCategory, setDetailCategory] = useState({
        title: '',
        description: '',
        sortOrder: 100,
        status: true,
    });
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getAllCategory = async () => {
        try {
            setIsLoading(true);
            const res = await categoryServicePost.getAllCategoryPost();
            setCategories(res.data.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (!openDetail) {
            getAllCategory();
        }
    }, [openDetail]);

    const handleClose = () => {
        setOpenDetail(false);
    };

    const deleteCategory = async (_id) => {
        try {
            const res = await categoryServicePost.deleteCategoryPost(_id);
            console.log(res);
            getAllCategory();
            dispatch(
                openAlert({
                    message: 'Xóa danh mục bài viết thành công',
                    severity: 'success',
                }),
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    return (
        <Box>
            <Box>
                <Box m='0rem 0.5rem 0.5rem 0.5rem'>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius='9px'
                        gap='3rem'
                        p='0.1rem 1.5rem'
                    >
                        <Button
                            variant='contained'
                            size='small'
                            color='success'
                            onClick={() => {
                                setDetailCategory({
                                    title: '',
                                    description: '',
                                    sortOrder: 100,
                                    status: true,
                                });
                                setOpenDetail(true);
                            }}
                        >
                            Thêm danh mục sản phẩm
                        </Button>
                    </FlexBetween>
                </Box>
            </Box>
            <Box>
                <NewCategoryPost
                    open={openDetail}
                    handleClose={handleClose}
                    category={detailCategory}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tên danh mục bài viết</TableCell>
                                <TableCell align='center'>
                                    Số vị trí hiển thị
                                </TableCell>
                                <TableCell align='center'>Trạng thái</TableCell>
                                <TableCell align='center'>Ngày tạo</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories?.map((category) => (
                                <TableRow key={category._id}>
                                    <TableCell>{category.title}</TableCell>
                                    <TableCell align='center'>
                                        {category.sortOrder}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Chip
                                            label={
                                                category.status
                                                    ? 'Đang hiển thị'
                                                    : 'Không hiển thị'
                                            }
                                            color={
                                                category.status
                                                    ? 'success'
                                                    : 'error'
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <FormatDate date={category.createdAt} />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            color='success'
                                            onClick={() => {
                                                setDetailCategory(category);
                                                setOpenDetail(true);
                                            }}
                                        >
                                            Chi tiết
                                        </Button>
                                        <Button
                                            sx={{ marginLeft: '10px' }}
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                deleteCategory(category._id);
                                            }}
                                            color='warning'
                                        >
                                            Xóa
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default CategoryPost;
