import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function SwitchStatus(props) {
    const { checked, label, onChange } = props;
    return (
        <FormControlLabel
            control={
                <Switch checked={checked} onChange={onChange} color='success' />
            }
            label={label}
        />
    );
}

export default SwitchStatus;
