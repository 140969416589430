import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useLayoutEffect, useState } from 'react';
import { login } from '../slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import { openAlert } from 'slices/alert/alertSlice';
function LoginForm() {
    const paperStyle = { padding: 10, height: '70vh', width: '25vw' };
    const inputStyle = { padding: 10 };
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null;

    if (user) {
        return <Navigate to='/dashboard' />;
    }

    const handleLogin = async () => {
        setLoading(true);
        setDisabledBtn(true);
        try {
            const res = await dispatch(login({ email, password })).unwrap();
            console.log(res.data.data);
            setLoading(false);
            setDisabledBtn(false);
            
            if (
                res.data.code === 'auth/login-successfully' &&
                !res.data.data.roles.includes('admin') &&
                !res.data.data.roles.includes('root') &&
                !res.data.data.roles.includes('manager')
            ) {
                dispatch(
                    openAlert({
                        message: 'Tài khoản không có quyền',
                        severity: 'error',
                    }),
                );
                return;
            }
            localStorage.setItem('user', JSON.stringify(res.data.data));
            // navigate('/dashboard');
        } catch (e) {
            console.log(e);
            setLoading(false);
            setDisabledBtn(false);
            const res = e.response.data;

            if (res.statusCode === 400 && res.code === 'auth/user-not-found') {
                dispatch(
                    openAlert({
                        message: 'Tài khoản hoặc mật khẩu không đúng',
                        severity: 'error',
                    }),
                );
            } else {
                dispatch(
                    openAlert({
                        message: 'Server lỗi, liên hệ với IT để kiểm tra',
                        severity: 'error',
                    }),
                );
            }
        }
        // dispatch(login({ email, password }))
        //   .unwrap()
        //   .then((data) => {
        //     console.log("Hoang 99990")
        //      // if (response.data.accessToken) {
        //         //     localStorage.setItem("user", JSON.stringify(response.data));
        //         // }
        //     setLoading(false);
        //     setDisabledBtn(false);
        //     // navigate('/dashboard');
        //   })
        //   .catch((e) => {
        //     setLoading(false);
        //     setDisabledBtn(false);
        //     if(e.statusCode === 400 && e.code ==="auth/user-not-found"){
        //       dispatch(openAlert({
        //         message: 'Tài khoản hoặc mật khẩu không đúng',
        //         severity: 'error'
        //       }));
        //     }
        //     else{
        //       dispatch(openAlert({
        //         message: 'Server lỗi, liên hệ với IT để kiểm tra',
        //         severity: 'error'
        //       }));
        //     }
        //   });
    };
    // console.log("Hoang 999888",isLoggedIn);

    return (
        <Grid>
            <Paper elevation={10} style={paperStyle}>
                <Grid align={'center'}>
                    <Avatar
                        alt='Remy Sharp'
                        src='	https://admin.deviot.vn/static/media/logo.126c9110.png'
                        sx={{ width: 80, height: 80 }}
                        margin='normal'
                    />
                    <Typography variant='h5' component='h5'>
                        Đăng nhập
                    </Typography>
                    <form>
                        <TextField
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            id='outlined-basic-email'
                            label='Email'
                            variant='outlined'
                            fullWidth
                            type='email'
                            margin='normal'
                        />
                        <TextField
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            id='outlined-basic-pwd'
                            label='Password'
                            variant='outlined'
                            fullWidth
                            type='password'
                            margin='normal'
                        />
                        {/* <Button
            disabled={true}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={async (e) => {
              e.preventDefault();
              console.log('hOANG 111 ',email,password);
              handleLogin();
              
            }}
          >
            Đăng nhập
      </Button> */}
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                disabled={disabledBtn}
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={async (e) => {
                                    e.preventDefault();
                                    handleLogin();
                                }}
                            >
                                Đăng nhập
                            </Button>
                            {loading && (
                                <CircularProgress
                                    size={26}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </form>
                </Grid>
            </Paper>
        </Grid>
    );
}

export default LoginForm;
