import instanceAxios from '../../helper/axios.config';
const URL_ORDER = 'admin/order';

const getAllOrders = (
    keywords = '',
    date = '',
    statusOrders,
    pageNum,
    pageSize,
) => {
    return instanceAxios.get(
        `${URL_ORDER}/admin-orders?keywords=${keywords}&date=${date}&statusOrders=${statusOrders}&pageNum=${pageNum}&pageSize=${pageSize}`,
    );
};

const updateStatusOrders = (idOrder, status) => {
    return instanceAxios.put(`${URL_ORDER}/update-status-order/${idOrder}`, {
        status,
    });
};

const deleteOrder = (id) => {
    // admin-orders
    return instanceAxios.delete(`${URL_ORDER}/admin-orders/${id}`);
};

// const createProduct = (newProduct) => {
//     return instanceAxios.post('/course', newProduct)
// }
const orderService = {
    getAllOrders,
    updateStatusOrders,
    deleteOrder,
};

export default orderService;
