import instanceAxios from '../../helper/axios.config';
const URL_CATEGORY_POST = '/admin/category-posts';

const getAllCategoryPost = () => {
    return instanceAxios.get(URL_CATEGORY_POST);
};
const createCategoryPost = (newCategory) => {
    return instanceAxios.post(URL_CATEGORY_POST, newCategory);
};

const deleteCategoryPost = (id) => {
    return instanceAxios.delete(`${URL_CATEGORY_POST}/${id}`);
};

const updateCategoryPost = (category) => {
    return instanceAxios.put(URL_CATEGORY_POST, category);
};

const categoryService = {
    getAllCategoryPost,
    createCategoryPost,
    deleteCategoryPost,
    updateCategoryPost,
};

export default categoryService;
