import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useGetCustomersQuery } from 'state/api';
import { DataGrid } from '@mui/x-data-grid';
import categoryPostService from 'services/categoryPost/categoryPost.service';
import postService from 'services/post/post.service';
import NewPost from './NewPost';
import FindProductByCategory from 'components/FindProductByCategory';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import { useNavigate } from 'react-router-dom';

const DEFAULT_CATEGORY = {
    slug: 'default-category',
    title: 'Chưa phân loại',
    description: 'Chưa phân loại',
};

const renderStatusPost = (props) => {
    const { value } = props;
    return (
        <>
            <Chip
                label={value ? 'Hoạt động' : 'Coming soon'}
                color={value ? 'success' : 'error'}
            />
        </>
    );
};
const Post = () => {
    const [categorySelected, setCategorySelected] = useState('');
    const [listCategories, setListCategories] = useState([]);
    const [post, setPosts] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [productSelected, setProductSelected] = useState();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        getProductByCategory(categorySelected);
        setOpen(false);
    };
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getProductByCategory = (type) => {
        postService
            .getAllPost(type)
            .then((res) => {
                setPosts(res.data.data.posts);
            })
            .catch((error) => {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            });
    };

    useEffect(() => {
        categoryPostService
            .getAllCategoryPost()
            .then((res) => {
                setListCategories([...res.data.data, DEFAULT_CATEGORY]);
                setCategorySelected(res.data.data[0].slug);
            })
            .catch((error) => {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            });
    }, []);

    useEffect(() => {
        if (categorySelected) {
            getProductByCategory(categorySelected);
        }
    }, [categorySelected]);

    const columns = [
        {
            field: 'title',
            headerName: 'Tiêu đề bài viết',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Mô tả',
            flex: 0.5,
        },
        {
            field: 'sortOrder',
            headerName: 'Vị trí hiển thị',
            flex: 0.4,
        },
        {
            field: 'isPublish',
            headerName: 'Trạng thái',
            flex: 0.4,
            renderCell: renderStatusPost,
        },

        //
        {
            // field: "roles",
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => {
                return (
                    <strong>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setProductSelected(props.row);
                                setOpen(true);
                            }}
                        >
                            Chi tiết
                        </Button>

                        <Button
                            variant='contained'
                            size='small'
                            sx={{
                                marginLeft: '5px',
                            }}
                            onClick={() => {
                                handleDeletePost(props.row._id);
                            }}
                        >
                            Xóa
                        </Button>
                    </strong>
                );
            },
        },
    ];

    const handleChange = (value) => {
        setCategorySelected(value);
    };

    const handleDeletePost = async (_id) => {
        const res = await postService.deletePost(_id);
        if (res?.data?.code === 'post/delete-post-success') {
            getProductByCategory(categorySelected);
            dispatch(
                openAlert({
                    message: 'Xóa bài viết thành cônh',
                    severity: 'success',
                }),
            );
        } else {
            dispatch(
                openAlert({
                    message: 'Xóa bài viết thất bại',
                    severity: 'error',
                }),
            );
        }
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box>
                <FindProductByCategory
                    sx={{ m: 1, minWidth: 200, maxHeight: 90, padding: '5px' }}
                    handleChange={handleChange}
                    categorySelected={categorySelected}
                    listCategories={listCategories}
                />
                <Button
                    onClick={() => {
                        setProductSelected({
                            title: '',
                            category: [DEFAULT_CATEGORY],
                            description: '',
                            urlVideoIntro: '',
                            isPublish: true,
                            content: '',
                            thumbnail: '',
                            sortOrder: 1,
                            rating: 4.7,
                            view: 500,
                            avatarAuthor:
                                'https://deviot.vn/storage/deviot/654a1ed195d96a8733c8.jpg',
                            isPublish: true,
                        });
                        setOpen(true);
                    }}
                    sx={{ m: 1, minWidth: 120, maxHeight: 90 }}
                    size='medium'
                    variant='contained'
                    color='success'
                >
                    Thêm bài viết
                </Button>
                <NewPost
                    open={open}
                    listCategoriesPost={listCategories}
                    handleClose={handleClose}
                    post={productSelected}
                />
            </Box>
            <Box height='75vh'>
                <DataGrid
                    columns={columns}
                    rows={post || []}
                    getRowId={(row) => row._id}
                    pageSizeOptions={[20, 25]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </Box>
    );
};

export default Post;
