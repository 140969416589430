import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import React from 'react';

function FindProductByCategory(props) {
    const {handleChange, categorySelected, listCategories, sx, title } =props;
    const changeCategorySelected = (e)=>{
        handleChange(e.target.value)
    }

    return (
        <FormControl sx={sx} size="small">
            <InputLabel id="demo-select-small-label">{title}</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={categorySelected}
                label={title}
                onChange={changeCategorySelected}
            >
              {
                listCategories.map(category =><MenuItem key={category.slug} value={category.slug}>{category.title}</MenuItem>)
              }
            </Select>
        </FormControl>
    )
}

export default React.memo(FindProductByCategory);