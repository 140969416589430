import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: false,
    message: null,
    severity: 'success'
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        openAlert: (state, action) => {
            return {
                status: true,
                message: action.payload.message,
                severity: action.payload.severity
            };
        },
        closeAlert: (state, action) => {
            return {
                status: false,
                message: "",
                severity: state.severity
            };
        },
    },
});



export const { openAlert, closeAlert } = alertSlice.actions
const { reducer } = alertSlice;
export default reducer;