import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import DetailUser from './DetailUser';
import usersService from 'services/user/user.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import { InputBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const renderStatusAccount = (props) => {
    const { value } = props;
    const isActive = props?.row?.isVerified;
    return (
        <>
            <Chip
                label={
                    !isActive ? 'Chưa kích hoạt' : !value ? 'Hoạt động' : 'Khóa'
                }
                color={!value && isActive ? 'success' : 'error'}
            />
        </>
    );
};

const Users = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [listUsers, setListUsers] = useState([]);
    const [userSelected, setUserSelected] = useState();
    const [keyWords, setKeyWords] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [rowCountState, setRowCountState] = useState(1);

    const getUsers = async (keyWords = '', pageNum, pageSize) => {
        try {
            setIsLoading(true);
            const users = await usersService.getAllUsers(
                keyWords,
                pageNum,
                pageSize,
            );

            setRowCountState(users.data.paginationInfo.totalDocument);
            setListUsers(users.data.users);
        } catch (error) {
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitFormSearch = async (e) => {
        e.preventDefault();
        const users = await usersService.getAllUsers('email', keyWords);
        setListUsers(users.data);
    };

    const handleUpdateUser = async (user) => {
        try {
            await usersService.updateUser(user);
            dispatch(
                openAlert({
                    message: 'Cập nhât tài khoản thành công',
                    severity: 'success',
                }),
            );
            await getUsers();
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            await usersService.deleteUser(id);
            dispatch(
                openAlert({
                    message: 'Xoá tài khoản người dùng thành công',
                    severity: 'success',
                }),
            );
            getUsers();
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const columns = useMemo(() => {
        return [
            {
                id: 'name',
                field: 'name',
                headerName: 'Tên khách hàng',
                flex: 0.5,
            },
            {
                id: 'email',
                field: 'email',
                headerName: 'email',
                flex: 0.5,
            },
            {
                id: 'phone',
                field: 'phone',
                headerName: 'Số điện thoại',
                flex: 0.4,
                // renderCell: (params) => {
                //   return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
                // },
            },
            {
                id: 'isBlocked',
                field: 'isBlocked',
                headerName: 'Trạng thái',
                flex: 0.5,
                renderCell: renderStatusAccount,
            },
            {
                id: 'action',
                headerName: 'Thao tác',
                flex: 0.5,
                renderCell: (props) => {
                    const user = props.row;
                    const isActive = user?.isVerified?.status;
                    const isAdmin =
                        user.roles.includes('root') ||
                        user.roles.includes('admin');
                    return (
                        <strong>
                            {/* {params.value.getFullYear()} */}
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    setOpenDetail(true);
                                    setUserSelected(user);
                                }}
                            >
                                Chi Tiết
                            </Button>
                            {!isActive && (
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='warning'
                                    sx={{ marginLeft: '10px' }}
                                    onClick={() => {
                                        user.isVerified = {
                                            status: true,
                                            code: null,
                                            startTime: null,
                                            endTime: null,
                                        };
                                        handleUpdateUser(props.row);
                                    }}
                                >
                                    Active
                                </Button>
                            )}
                            {isActive && (
                                <>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        color='warning'
                                        sx={{ marginLeft: '10px' }}
                                        disabled={isAdmin}
                                        onClick={() => {
                                            handleDeleteUser(user?._id);
                                        }}
                                    >
                                        Xóa
                                    </Button>
                                    <Button
                                        variant='contained'
                                        size='small'
                                        sx={{ marginLeft: '10px' }}
                                        disabled={isAdmin}
                                        color={
                                            !props?.row?.isBlocked
                                                ? 'success'
                                                : 'error'
                                        }
                                        onClick={() => {
                                            if (!user.isBlocked) {
                                                user.accessToken = null;
                                                user.refreshToken = null;
                                            }
                                            user.isBlocked = !user.isBlocked;
                                            handleUpdateUser(props.row);
                                        }}
                                    >
                                        {!user?.isBlocked ? 'Block' : 'Unblock'}
                                    </Button>
                                </>
                            )}
                        </strong>
                    );
                },
            },
        ];
    }, []);
    const handleClose = useCallback(() => {
        getUsers();
        setOpenDetail(false);
    }, []);

    const handlePaginationModelChange = (newPaginationModel) => {
        getUsers('', newPaginationModel.page + 1, newPaginationModel.pageSize);
        setPaginationModel(newPaginationModel);
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box m='0rem 0.5rem 0.5rem 0.5rem'>
                <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius='9px'
                    gap='3rem'
                    p='0.1rem 1.5rem'
                    width='300px'
                >
                    <form onSubmit={handleSubmitFormSearch}>
                        <InputBase
                            placeholder='Nhập để tìm kiếm'
                            value={keyWords}
                            onChange={(e) => {
                                getUsers(e.target.value);
                                setKeyWords(e.target.value);
                            }}
                        />
                    </form>
                </FlexBetween>
            </Box>
            <Box height='80vh'>
                <DetailUser
                    open={openDetail}
                    user={userSelected}
                    handleClose={handleClose}
                />
                <DataGrid
                    rowCount={rowCountState}
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={listUsers}
                    columns={columns}
                    pageSizeOptions={[20, 25]}
                    paginationMode='server'
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                />
            </Box>
        </Box>
    );
};

export default Users;
