import React, { useEffect, useState } from 'react';
import FlexBetween from 'components/FlexBetween';
import Header from 'components/Header';
import { DownloadOutlined } from '@mui/icons-material';
import { Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import OverviewChart from 'components/OverviewChart';
import StatBox from 'components/StatBox';
import revenueService from '../../services/revenue/revenue.service';
import { formatCurrency } from '../../helper/currencyHelper';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';

const Dashboard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isNonMediumScreens = useMediaQuery('(min-width: 1200px)');
    const [totalStatisticalCurrentMonth, setTotalStatisticalCurrentMonth] =
        useState([]);
    const [totalStatisticalCurrentYear, setTotalStatisticalCurrentYear] =
        useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [topProductBestSeller, setTopProductBestSeller] = useState();

    const getRevenue12Days = async () => {
        const res = await revenueService.getRevenueByDate();
        setTotalSales([
            {
                id: 'orders',
                color: '#2ecc71',
                data: res.data.data?.map((item) => ({
                    y: item.totalRevenue,
                    x: item.date,
                })),
            },
        ]);
    };

    const getRevenueGroupMonth = async () => {
        const currentYear = new Date().getFullYear();
        const res = await revenueService.getRevenueGroupByMonth(currentYear);
        setTotalStatisticalCurrentMonth(res.data.data[0]);
    };

    const getRevenueGroupYear = async () => {
        const res = await revenueService.getRevenueGroupByYear();
        setTotalStatisticalCurrentYear(res.data.data);
    };

    const getTopProductsBestSeller = async () => {
        const res = await revenueService.getTopProductsBestSeller(15);
        setTopProductBestSeller(res.data.data);
    };

    const getDataDashboardPage = async () => {
        try {
            await Promise.all([
                getRevenue12Days(),
                getRevenueGroupMonth(),
                getRevenueGroupYear(),
                getTopProductsBestSeller(),
            ]);
            // console.log('test ', data);
        } catch (error) {
            console.log('hello', error.response.data.code);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        getDataDashboardPage();
    }, []);
    const columns = [
        {
            field: 'productName',
            headerName: 'Tên sản phẩm',
            flex: 1,
        },
        {
            field: 'totalQuantity',
            headerName: 'Số lượng đã bán',
            flex: 1,
        },
        {
            field: 'totalRevenue',
            headerName: 'Gía trị sản phẩm đã bán',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => formatCurrency(params.value),
        },
    ];

    return (
        <Box m='1.5rem 2.5rem'>
            <FlexBetween>
                <Header title='Trang quản trị' />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: '#e0e0e0',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        {/* <a href="/frmofmro">Welcome to your dashboard</a> */}
                        <DownloadOutlined sx={{ mr: '10px' }} />
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>

            <Box
                mt='10px'
                display='grid'
                gridTemplateColumns='repeat(12, 1fr)'
                gridAutoRows='160px'
                gap='10px'
                sx={{
                    '& > div': {
                        gridColumn: isNonMediumScreens ? undefined : 'span 12',
                    },
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title={`Tổng doanh thu trong tháng`}
                    value={
                        formatCurrency(
                            totalStatisticalCurrentMonth?.totalRevenue,
                        ) || 0
                    }
                />
                <StatBox
                    title='Tổng đơn hàng trong tháng '
                    value={totalStatisticalCurrentMonth?.totalSales || 0}
                />
                <Box
                    gridColumn='span 8'
                    gridRow='span 2'
                    // backgroundColor='#e0e0e0'
                    p='1rem'
                    // borderRadius='0.55rem'
                >
                    {/* CHART DOANH THU */}
                    <OverviewChart
                        view='sales'
                        isDashboard={true}
                        data={totalSales}
                    />
                </Box>
                <StatBox
                    title={`Doanh thu năm ${totalStatisticalCurrentYear[1]?.year}`}
                    value={formatCurrency(
                        totalStatisticalCurrentYear[1]?.totalRevenue,
                    )}
                />
                <StatBox
                    title={`Doanh thu năm ${totalStatisticalCurrentYear[0]?.year}`}
                    value={formatCurrency(
                        totalStatisticalCurrentYear[0]?.totalRevenue,
                    )}
                />

                {/* ROW 2 */}
                <Box
                    gridColumn='span 12'
                    gridRow='span 3'
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            borderRadius: '5rem',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#e0e0e0',
                            color: theme.palette.secondary[100],
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: '#e0e0e0',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#e0e0e0',
                            color: theme.palette.secondary[100],
                            borderTop: 'none',
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <Header subtitle='Top các sản phẩm bán chạy nhất' />
                    {!topProductBestSeller ? (
                        <div>data</div>
                    ) : (
                        <DataGrid
                            loading={!topProductBestSeller}
                            getRowId={(row) => row._id}
                            rows={topProductBestSeller || []}
                            columns={columns}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
