import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import sliderService from 'services/slider/slider.service';
import DetailSlider from './DetailSlider';
import { useNavigate } from 'react-router-dom';

const Slider = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [sliders, setSliders] = useState([]);
    const [slider, setSlider] = useState({
        actionLink: '',
        description: '',
        isPublish: true,
        linkToPost: '',
        title: '',
        urlImage: '',
    });

    const [keyWords, setKeyWords] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });

    const getAllSlider = async () => {
        try {
            const slider = await sliderService.getAllSliders();
            setSliders(slider.data.data.listSliders);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const handleSubmitFormSearch = async (e) => {
        console.log(e.target.value);
    };
    const handleDeleteSlider = async (id) => {
        try {
            await sliderService.deleteSlider(id);
            dispatch(
                openAlert({
                    message: 'Xoá slider thành công',
                    severity: 'success',
                }),
            );
            getAllSlider();
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        getAllSlider();
    }, [openDetail]);

    const columns = [
        {
            field: 'title',
            headerName: 'Tiêu đề',
            flex: 0.5,
        },
        {
            field: 'createdAt',
            headerName: 'Ngày tạo',
            flex: 0.4,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            field: 'isPublish',
            headerName: 'Trạng thái',
            flex: 0.5,
            renderCell: (params) => {
                const status = params.value;

                return (
                    <Chip
                        label={status ? 'Đang hiển thi' : 'Không hiển thị'}
                        color={status ? 'success' : 'error'}
                    />
                );
            },
        },
        {
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => (
                <strong>
                    {/* {params.value.getFullYear()} */}
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            setSlider(props.row);
                            setOpenDetail(true);
                            console.log('Voucher ', props.row);
                        }}
                    >
                        Chi Tiết
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        color='warning'
                        onClick={() => {
                            console.log('DELETE: ', props.row._id);
                            handleDeleteSlider(props.row._id);
                        }}
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Xóa
                    </Button>
                </strong>
            ),
        },
    ];

    const handleClose = () => {
        setOpenDetail(false);
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box m='0rem 0.5rem 0.5rem 0.5rem' sx={{ display: 'flex' }}>
                <FlexBetween
                    sx={{
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='success'
                        onClick={() => {
                            setSlider({
                                actionLink: '',
                                description: '',
                                isPublish: true,
                                linkToPost: '',
                                title: '',
                                urlImage: '',
                            });
                            setOpenDetail(true);
                        }}
                    >
                        Thêm slider
                    </Button>
                </FlexBetween>
            </Box>
            <Box height='80vh'>
                <DetailSlider
                    open={openDetail}
                    slider={slider}
                    handleClose={handleClose}
                />
                <DataGrid
                    getRowId={(row) => row._id}
                    rows={sliders || []}
                    columns={columns}
                    pageSizeOptions={[20, 25]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </Box>
    );
};

export default Slider;
