import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "services/auth/auth.service";



const user = JSON.parse(localStorage.getItem("user"));
export const login = createAsyncThunk(
    "auth/login",
    async ({ email, password }, thunkAPI) => {
        try {
            const data = await authService.login(email, password);
            return data;
        } catch (error) {
            console.log("Lỗi ", error.response.status, error.response.data.code)
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});

export const { loginFulfilled, loginRejected } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;