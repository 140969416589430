import instanceAxios from '../../helper/axios.config';
const URL_VOUCHER = 'admin/vouchers';

async function getAllVouchers(q = '', pageNum, pageSize) {
    return instanceAxios.get(
        `${URL_VOUCHER}?q=${q}&pageNum=${pageNum}&pageSize=${pageSize}`,
    );
}

const deleteVoucher = (id) => {
    return instanceAxios.delete(`${URL_VOUCHER}/${id}`);
};

const createVoucher = (voucher) => {
    return instanceAxios.post(`${URL_VOUCHER}`, voucher);
};

const updateVoucher = (voucher) => {
    return instanceAxios.put(`${URL_VOUCHER}`, voucher);
};

const createVouchers = (vouchers) => {
    return instanceAxios.post(`${URL_VOUCHER}`, vouchers);
};

const voucherService = {
    getAllVouchers,
    deleteVoucher,
    updateVoucher,
    createVoucher,
    createVouchers,
};

export default voucherService;
