import React, { useState, useMemo } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
// import { useGetUserQuery } from 'state/api';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { themeSettings } from 'theme';
import { createTheme } from '@mui/material/styles';

const Layout = () => {
    const isNonMobile = useMediaQuery('(min-width: 600px)');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    // const userId = useSelector((state) => state.global.userId);
    // const { data } = useGetUserQuery(userId);
    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                display={isNonMobile ? 'flex' : 'block'}
                width='100%'
                height='100%'
            >
                <Sidebar
                    user={{}}
                    isNonMobile={isNonMobile}
                    drawerWidth='250px'
                    isSidebarOpen={isSidebarOpen}
                    setIsSidebarOpen={setIsSidebarOpen}
                />
                <Box flexGrow={1}>
                    <Navbar
                        user={{}}
                        isSidebarOpen={isSidebarOpen}
                        setIsSidebarOpen={setIsSidebarOpen}
                    />
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Layout;
