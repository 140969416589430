import LoginForm from "components/LoginForm";
import FlexCenter from "components/FlexCenter"
function Login() {
    return ( 

    <FlexCenter height="100%"><LoginForm/></FlexCenter> 
       
    );
}

export default Login;