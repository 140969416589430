import { Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import usersService from 'services/user/user.service';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import userService from '../../../services/user/user.service.js';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate } from 'react-router-dom';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const roles = ['admin', 'manager', 'root', 'user'];

function DetailUser(props) {
    const { open, handleClose, user } = props;
    const [detailUser, setDetailUser] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [validate, setValidate] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getDetailUser = async (id) => {
        const res = await userService.getDetailUser(id);
        setDetailUser(res.data.data);
        return res;
    };

    useEffect(() => {
        if (user?._id) {
            getDetailUser(user?._id);
        }
    }, [user]);

    const updateDetailUser = async (user) => {
        const res = await usersService.updateUser(user);
        if (
            res.data.code === 'user/update-user-success' &&
            res.data.statusCode === 200
        ) {
            dispatch(
                openAlert({
                    message: 'Cập nhât tài khoản thành công',
                    severity: 'success',
                }),
            );
        } else {
            dispatch(
                openAlert({
                    message: 'Cập nhât tài khoản thất bại',
                    severity: 'error',
                }),
            );
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleResetPassword = async () => {
        if (!newPassword && user?._id) {
            setValidate(true);
        } else {
            try {
                await usersService.resetPassword(user?._id, {
                    password: newPassword,
                });
                dispatch(
                    openAlert({
                        message: 'Reset mật khẩu thành công',
                        severity: 'success',
                    }),
                );
            } catch (error) {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            }
        }
    };

    const deleteCourse = (_index) => {
        setDetailUser((prevDetailUser) => {
            updateDetailUser({
                ...prevDetailUser,
                listCourseRegister: prevDetailUser?.listCourseRegister?.filter(
                    (c, index) => index !== _index,
                ),
            });
            return {
                ...prevDetailUser,
                listCourseRegister: prevDetailUser?.listCourseRegister?.filter(
                    (c, index) => index !== _index,
                ),
            };
        });
    };
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setDetailUser((preUser) => {
            updateDetailUser({
                ...preUser,
                roles: value,
            });
            return {
                ...preUser,
                roles: value,
            };
        });
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogContent>
                <DialogTitle>Thông tin chi tiết người dùng</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Tên khách hàng:</strong>
                                {detailUser?.name}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Email:</strong>
                                {detailUser?.email}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Số điện thoại:</strong>
                                {detailUser?.phone}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Trạng thái kích họat</strong>
                                {!detailUser?.isVerified.status
                                    ? 'Tài khoản đã được kích hoạt'
                                    : 'Tài khoản chưa được kích họa'}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '70%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <div>Reset mật khẩu</div>
                            <FormControl
                                error={validate}
                                sx={{ m: 1, width: '50%' }}
                                variant='standard'
                            >
                                <InputLabel htmlFor='standard-adornment-password'>
                                    Mật khẩu mới
                                </InputLabel>
                                <Input
                                    id='standard-adornment-password'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {validate && (
                                    <FormHelperText id='component-helper-text'>
                                        Mật khẩu không được để trống
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Button
                                onClick={handleResetPassword}
                                sx={{ m: 1 }}
                                size='small'
                                variant='contained'
                                color='success'
                            >
                                Reset mật khẩu
                            </Button>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: '50%',
                            margin: '5px',
                            boxSizing: 'content-box',
                        }}
                    >
                        <div>
                            <div>
                                <i>* Root quyền truy cập toàn hệ thống</i>
                            </div>
                            <div>
                                <i>* Admin quyền truy cập toàn hệ thống</i>
                            </div>
                            <div>
                                <i>
                                    * Manger quyền truy hệ thống quản lý bài
                                    viết
                                </i>
                            </div>
                        </div>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id='demo-multiple-checkbox-label'>
                                Quyền truy cập hệ thống
                            </InputLabel>
                            <Select
                                labelId='demo-multiple-checkbox-label'
                                id='demo-multiple-checkbox'
                                multiple
                                value={detailUser?.roles || []}
                                onChange={handleChange}
                                input={
                                    <OutlinedInput label=' Quyền truy cập hệ thống' />
                                }
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {roles.map((role) => (
                                    <MenuItem
                                        key={role}
                                        value={role}
                                        disabled={
                                            (detailUser?.roles?.indexOf(role) >
                                                -1 &&
                                                role === 'user') ||
                                            role === 'root'
                                        }
                                    >
                                        <Checkbox
                                            checked={
                                                detailUser?.roles?.indexOf(
                                                    role,
                                                ) > -1
                                            }
                                            disabled={
                                                (detailUser?.roles?.indexOf(
                                                    role,
                                                ) > -1 &&
                                                    role === 'user') ||
                                                role === 'root'
                                            }
                                        />
                                        <ListItemText primary={role} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {/* Danh sách các bài học */}
                    <hr />

                    <Box>
                        {detailUser?.listCourseRegister.length ? (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Khóa học </TableCell>
                                            <TableCell>Ngày bắt đầu </TableCell>
                                            <TableCell>Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detailUser?.listCourseRegister.map(
                                            (item, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {item?.title ||
                                                                `Tên bài học`}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.startDate}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button
                                                                size='small'
                                                                variant='contained'
                                                                color='warning'
                                                                onClick={() =>
                                                                    deleteCourse(
                                                                        index,
                                                                    )
                                                                }
                                                            >
                                                                Xóa
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            },
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <strong>Chưa có khóa học</strong>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <Button 
                onClick={()=>{
                    handleSubmitProduct()
                    console.log("Product", detailProduct);
                }}
                sx={{marginRight: '5px'}}
                        size="small" variant="contained" color="success"
                        >{detailProduct?._id ?  `Chỉnh sửa sản phẩm` : `Thêm sản phẩm`}
                </Button> */}
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default memo(DetailUser);
