import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import videoIntroBlogService from 'services/videoIntroBlog/videoIntroBlogService';
import { useNavigate } from 'react-router-dom';

function VideoIntroDetail(props) {
    const { open, handleClose, video } = props;

    const [videoIntroDetail, setVideoIntroDetail] = useState(video);
    useEffect(() => {
        setVideoIntroDetail(video);
    }, [video]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handSubmit = async () => {
        try {
            if (videoIntroDetail?._id) {
                const res = await videoIntroBlogService.updateVideoIntroBlog(
                    videoIntroDetail,
                );

                handleClose();
                dispatch(
                    openAlert({
                        message: 'Cập nhật video giới thiệu thành công',
                        severity: 'success',
                    }),
                );
            } else {
                const res = await videoIntroBlogService.createVideoIntroBlog(
                    videoIntroDetail,
                );
                setVideoIntroDetail({
                    title: '',
                    description: '',
                    urlVideoIntro: '',
                });
                handleClose();
                dispatch(
                    openAlert({
                        message: 'Tạo danh video giới thiệu thành công',
                        severity: 'success',
                    }),
                );
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const handleChangeVideoIntro = (key, value) => {
        switch (key) {
            case 'title':
                setVideoIntroDetail((preVideo) => ({
                    ...preVideo,
                    title: value,
                }));
                break;

            case 'description':
                setVideoIntroDetail((preVideo) => ({
                    ...preVideo,
                    description: value,
                }));
                break;

            case 'urlVideoIntro':
                setVideoIntroDetail((preVideo) => ({
                    ...preVideo,
                    urlVideoIntro: value,
                }));
                break;
            default:
                break;
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogContent>
                <DialogTitle>Thông tin chi tiết danh mục sản phẩm</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Tiêu đề video'
                                    type='text'
                                    size='medium'
                                    value={videoIntroDetail?.title}
                                    onChange={(e) =>
                                        handleChangeVideoIntro(
                                            'title',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Mô tả video'
                                    type='text'
                                    size='medium'
                                    value={videoIntroDetail?.description}
                                    onChange={(e) =>
                                        handleChangeVideoIntro(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Link video giới thiệu'
                                    type='text'
                                    size='medium'
                                    value={videoIntroDetail?.urlVideoIntro}
                                    onChange={(e) =>
                                        handleChangeVideoIntro(
                                            'urlVideoIntro',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handSubmit();
                    }}
                    sx={{ marginRight: '5px' }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    {videoIntroDetail?._id
                        ? 'Cập nhật danh mục'
                        : 'Thêm danh mục sản phẩm'}
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default VideoIntroDetail;
