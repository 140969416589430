import instanceAxios from '../../helper/axios.config';
//type = null, email = null, page = 1, itemsPerPage = 15
const URL_USERS = '/admin/users';
const URL_RESET_PASSWORD = '/admin/reset-password';

const getAllUsers = (keywords, pageNum = 1, pageSize = 20) => {
    return instanceAxios.get(
        `${URL_USERS}?keywords=${keywords}&pageNum=${pageNum}&pageSize=${pageSize}`,
    );
};

const updateUser = (user) => {
    return instanceAxios.put(URL_USERS, user);
};

const deleteUser = (id) => {
    return instanceAxios.delete(`${URL_USERS}/${id}`);
};

const getDetailUser = (id) => {
    return instanceAxios.get(`${URL_USERS}/${id}`);
};

const resetPassword = (id, info) => {
    return instanceAxios.put(`${URL_RESET_PASSWORD}/${id}`, info);
};

const usersService = {
    getDetailUser,
    getAllUsers,
    updateUser,
    deleteUser,
    resetPassword,
};

export default usersService;
