import instanceAxios from '../../helper/axios.config';

const getRevenueByDate = async function (startDate, endDate, limit) {
    let params = '';
    console.log('startDate', startDate);
    if (startDate) {
        params += `?startDate=${startDate}`;
    }
    if (endDate) {
        params += `&endDate=${endDate}`;
    }
    if (limit) {
        params += `&limit=${limit}`;
    }
    return instanceAxios.get(`/get-revenue-group-day${params}`);
};

const getRevenueGroupByMonth = async function (year) {
    let params = '';
    if (year) {
        params = `?year=${year}`;
    }
    return instanceAxios.get(`/get-revenue-group-month${params}`);
};

const getRevenueGroupByYear = async function () {
    return instanceAxios.get('/get-revenue-group-year');
};

const getTopProductsBestSeller = async (limit) => {
    let params = '';
    if (limit) {
        params = `?limit=${limit}`;
    }
    return instanceAxios.get(`/get-top-products-best-seller${params}`);
};
const revenueService = {
    getRevenueByDate,
    getRevenueGroupByMonth,
    getRevenueGroupByYear,
    getTopProductsBestSeller,
};

export default revenueService;
