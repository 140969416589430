import instanceAxios from '../../helper/axios.config';
const URL_PRODUCT = '/admin/products';

export const getAllProducts = (
    category = 'khoa-hoc-lap-trinh',
    pageNum = 1,
    pageSize = 10000000,
) => {
    return instanceAxios.get(
        `${URL_PRODUCT}?category=${category}&pageNum=${pageNum}&pageSize=${pageSize}`,
    );
};

const getDetailCourseById = (id) => {
    return instanceAxios.get('course', {
        params: {
            id: id,
        },
    });
};

const deleteProduct = (id) => {
    return instanceAxios.delete(`${URL_PRODUCT}/${id}`);
};

const createProduct = (newProduct) => {
    return instanceAxios.post(URL_PRODUCT, newProduct);
};
const updateProduct = (product) => {
    return instanceAxios.put(URL_PRODUCT, product);
};
const productService = {
    getAllProducts,
    getDetailCourseById,
    createProduct,
    deleteProduct,
    updateProduct,
};

export default productService;
