import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import videoIntroBlogService from 'services/videoIntroBlog/videoIntroBlogService';
import VideoIntroDetail from './VideoIntroDetail';
import FormatDate from 'components/FormatDate';
import { useNavigate } from 'react-router-dom';

const VideoIntroBlog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [detailVideoIntroBlog, setVideoIntroBlog] = useState({
        title: '',
        description: '',
        urlVideoIntro: '',
    });
    const [videosIntroBlog, setVideosIntroBlog] = useState([]);

    const getAllVideosIntroBlog = async () => {
        try {
            const res = await videoIntroBlogService.getAllVideosIntroBlog();
            console.log(res.data.data);
            setVideosIntroBlog(res.data.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);

    useEffect(() => {
        if (!openDetail) {
            getAllVideosIntroBlog();
        }
    }, [openDetail]);

    const handleClose = () => {
        setOpenDetail(false);
    };

    const handleDeleteVideoIntro = async (_id) => {
        try {
            const res = await videoIntroBlogService.deleteVideoIntroBlog(_id);
            console.log(res);
            getAllVideosIntroBlog();
            dispatch(
                openAlert({
                    message: 'Xóa video giới thiệu thành công',
                    severity: 'success',
                }),
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    return (
        <Box>
            <Box>
                <Box m='0rem 0.5rem 0.5rem 0.5rem'>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius='9px'
                        gap='3rem'
                        p='0.1rem 1.5rem'
                    >
                        <Button
                            variant='contained'
                            size='small'
                            color='success'
                            onClick={() => {
                                setVideoIntroBlog({
                                    title: '',
                                    description: '',
                                    urlVideoIntro: '',
                                });
                                setOpenDetail(true);
                            }}
                        >
                            Thêm video giới thiệu
                        </Button>
                    </FlexBetween>
                </Box>
            </Box>
            <Box>
                <VideoIntroDetail
                    open={openDetail}
                    handleClose={handleClose}
                    video={detailVideoIntroBlog}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tiêu đề video</TableCell>
                                <TableCell align='center'>Ngày tạo</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {videosIntroBlog?.map((video) => (
                                <TableRow key={video._id}>
                                    <TableCell>{video.title}</TableCell>
                                    <TableCell align='center'>
                                        <FormatDate date={video.createdAt} />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            color='success'
                                            onClick={() => {
                                                setVideoIntroBlog(video);
                                                setOpenDetail(true);
                                            }}
                                        >
                                            Chi tiết
                                        </Button>
                                        <Button
                                            sx={{ marginLeft: '10px' }}
                                            variant='contained'
                                            size='small'
                                            onClick={() => {
                                                handleDeleteVideoIntro(
                                                    video._id,
                                                );
                                            }}
                                            color='warning'
                                        >
                                            Xóa
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default VideoIntroBlog;
