import { Box, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import voucherService from "services/voucher/voucher.service";


function DetailVoucher(props) {
    const {open, handleClose, voucher} = props;
    const [detailVoucher,setDetailVoucher] = useState();
    const dispatch = useDispatch();
    // console.log("Voucher: ",voucher)
    
    useEffect(()=>{
        setDetailVoucher(voucher)
    },[voucher])
    
    const handleSubmitVoucher = async ()=>{
        if(detailVoucher._id)
        {
            const res = await voucherService.updateVoucher(detailVoucher);
            console.log(res)
            if(res.data.code === 'voucher/update-success')
            {
                dispatch(openAlert({
                    message: 'Cập nhật voucher thành công',
                    severity: 'success',
                }));
                handleClose();
            }
            else{
                
                dispatch(openAlert({
                    message: 'Cập nhật voucher thất bại',
                    severity: 'error',
                }));
            }
        }
        else{
            const res = await voucherService.createVoucher(detailVoucher);
            console.log(res)
            if(res.data.code === 'voucher/create-success')
            {
                dispatch(openAlert({
                    message: 'Tạo voucher thành công',
                    severity: 'success',
                }));
                handleClose();
            }
            else{
                
                dispatch(openAlert({
                    message: 'Tạo voucher thất bại',
                    severity: 'error',
                }));
            }
        }
    }

    const onChangeDetailVoucher = (value, type)=>{
        switch (type) {
            case 'discountCode':
                setDetailVoucher((preDetailVoucher)=>{
                    return{
                        ...preDetailVoucher,
                        discountCode: value,
                    }
                })
                break;
            
            case 'discount':
                setDetailVoucher((preDetailVoucher)=>{
                    return{
                        ...preDetailVoucher,
                        discount: value,
                    }
                })
                break;
            
            case 'numberOfUses':
                setDetailVoucher((preDetailVoucher)=>{
                    return{
                        ...preDetailVoucher,
                        numberOfUses: value,
                    }
                })
                break;

            case 'startDate':
                setDetailVoucher((preDetailVoucher)=>{
                    return{
                        ...preDetailVoucher,
                        startDate: value,
                    }
                })
                break;

            case 'endDate':
                setDetailVoucher((preDetailVoucher)=>{
                    return{
                        ...preDetailVoucher,
                        endDate: value,
                    }
                })
                break;
            default:
                break;
        }
    }

    return ( 
        <Dialog 
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
            <DialogContent 
            >
                <DialogTitle>
                    {detailVoucher?._id ? `Thông tin voucher` : `Tạo voucher`}
                </DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                            width: '100%',
                            margin: '5px',
                            boxSizing: 'content-box'
                            }}
                        >
                          
                            <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                            <TextField
                                label="Mã khuyến mãi"
                                type="text"
                                size="medium"
                                value = {detailVoucher?.discountCode}
                                onChange={(e)=>{
                                    onChangeDetailVoucher(e.target.value,'discountCode');
                                }}
                                sx={{
                                    width: '100%'
                                }}
                                />
                            </Box>

                            <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                               <TextField
                                label="Gía trị khuyến mãi"
                                type="text"
                                size="medium"
                                value = {detailVoucher?.discount}
                                sx={{
                                    width: '100%'
                                }}
                                onChange={(e )=>{
                                    onChangeDetailVoucher(e.target.value,'discount');
                                }}
                                />
                            </Box>

                            <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                           <TextField
                                label="Số lượt dùng mã khuyến mãi"
                                type="text"
                                size="medium"
                                value = {detailVoucher?.numberOfUses}
                                sx={{
                                    width: '100%'
                                }}
                                onChange={(e)=>{
                                    onChangeDetailVoucher(e.target.value,'numberOfUses');
                                }}
                                />
                            </Box>
                            

                            <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    value={dayjs(detailVoucher?.startDate)}
                                    label="Ngày bắt đầu"
                                    onChange={(newValue)=>{
                                        const date = new Date(newValue);
                                        onChangeDetailVoucher(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,'startDate');
                                    }}
                                   />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                    sx={{marginLeft: '15px'}}
                                    value={dayjs(detailVoucher?.endDate)}
                                    label="Ngày kết thúc"
                                    onChange={(newValue)=>{
                                        const date = new Date(newValue);
                                        onChangeDetailVoucher(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,'endDate');
                                    }}
                                   />
                                </LocalizationProvider>
                            </Box>

                        </Box>
                    </Box>
                   
                    
                  
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                onClick={handleSubmitVoucher}
                sx={{marginRight: '5px'}}
                        size="small" variant="contained" color="success"
                        >{detailVoucher?._id ?  `Cập nhật voucher` : `Thêm sản phẩm`}
                </Button>
                <Button
                    onClick={handleClose}
                    size="small" variant="contained" color="warning"
                    >Đóng 
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DetailVoucher;