import instanceAxios from '../../helper/axios.config';
const URL_ADMIN = 'admin/video-intro-blog';
async function getAllVideosIntroBlog(q = '') {
    return instanceAxios.get(`${URL_ADMIN}?q=${q}`);
}

const deleteVideoIntroBlog = (id) => {
    return instanceAxios.delete(`${URL_ADMIN}/${id}`);
};

const createVideoIntroBlog = (voucher) => {
    return instanceAxios.post(URL_ADMIN, voucher);
};

const updateVideoIntroBlog = (voucher) => {
    return instanceAxios.put(URL_ADMIN, voucher);
};

const voucherService = {
    createVideoIntroBlog,
    updateVideoIntroBlog,
    deleteVideoIntroBlog,
    getAllVideosIntroBlog,
};

export default voucherService;
