import instanceAxios from '../../helper/axios.config';
const URL_CODE_FOR_KIT = '/admin/codeForKit';
const URL_CODE_FOR_KIT_FAST = '/admin/codeForKitFast';

const getAllCodeForKit = (keywords = '', pageSize = 1, pageNum = 30) => {
    return instanceAxios.get(
        `${URL_CODE_FOR_KIT}?q=${keywords}&pageSize=${pageSize}&pageNum=${pageNum}`,
    );
};

const createCodeForKitFast = (infoCodeForKits) => {
    return instanceAxios.post(URL_CODE_FOR_KIT_FAST, infoCodeForKits);
};

const deleteCodeForKit = (id) => {
    return instanceAxios.delete(`${URL_CODE_FOR_KIT}/${id}`);
};

const codeForKitService = {
    createCodeForKitFast,
    getAllCodeForKit,
    deleteCodeForKit,
};

export default codeForKitService;
