import instanceAxios from '../../helper/axios.config';
const URL_CATEGORY = '/admin/categories';

const getAllCategory = () => {
    return instanceAxios.get(`${URL_CATEGORY}?status=all`);
};
const createCategory = (newCategory) => {
    return instanceAxios.post(URL_CATEGORY, newCategory);
};

const deleteCategory = (_id) => {
    const body = {
        _id: _id,
    };
    return instanceAxios.delete(`${URL_CATEGORY}/${_id}`, body);
};

const updateCategory = (category) => {
    return instanceAxios.put(URL_CATEGORY, category);
};

const categoryService = {
    getAllCategory,
    createCategory,
    deleteCategory,
    updateCategory,
};

export default categoryService;
