import { Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, memo, useState } from 'react';
import FindProductByCategory from '../../../components/FindProductByCategory';
import productService from '../../../services/product/product.service';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Lesson from './Lesson/Lesson';
import uploadFileService from '../../../services/upload/upload.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import Editor from 'components/Editor';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SwitchStatus from 'components/SwitchStatus';
import { v4 as uuidv4 } from 'uuid';
import CheckBox from '../../../components/CheckBox';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const listTypeProduct = [
    {
        title: 'Khóa học',
        slug: 'course',
    },
    {
        title: 'Kit',
        slug: 'kit',
    },
];

function NewProduct(props) {
    const { open, onClosePopUpNewProduct, product, listCategories } = props;
    const [detailProduct, setDetailProduct] = useState();
    const [thumbnailSelected, setThumbnailSelected] = useState();
    const [urlFilePreview, setUrlFilePreview] = useState();
    const [openLesion, setOpenLesion] = useState(false);
    const [lessonSelected, setLessonSelected] = useState({
        titleLesson: '',
        urlVideo: '',
    });
    const [newPurposeLesson, setNewPurposeLesson] = useState('');
    const [newRequirement, setNewRequirement] = useState('');
    const dispatch = useDispatch();
    const [newSoftware, setNewSoftware] = useState({
        name: '',
        description: '',
        linkDownload: '',
    });

    const [galleryImages, setGalleryImages] = useState([]);
    console.log('RE-RENDER');

    useEffect(() => {
        if (product?._id) {
            productService.getDetailCourseById(product._id).then((res) => {
                console.log(res.data);
                setDetailProduct(res.data);
                setGalleryImages(res.data.galleryImages);
            });
        } else {
            setDetailProduct({});
        }
        setThumbnailSelected();
    }, [product]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setThumbnailSelected(undefined);
            return;
        }
        setThumbnailSelected(e.target.files[0]);
    };

    const addImageGallery = (e) => {
        const file = e.target.files[0];
        if (!file || file.length === 0) {
            return;
        }
        setGalleryImages((preGalleryImages) => [
            ...preGalleryImages,
            {
                file: file,
                src: URL.createObjectURL(file),
            },
        ]);
    };

    useEffect(() => {
        if (!thumbnailSelected) {
            setUrlFilePreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(thumbnailSelected);
        setUrlFilePreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnailSelected]);

    const handleOnChangeDetailProduct = (e, type) => {
        switch (type) {
            case 'notSellable':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        notSellable: e,
                    };
                });
                break;

            case 'title':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        title: e.target.value,
                    };
                });
                break;

            case 'rating':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        rating: e.target.value,
                    };
                });
                break;

            case 'studentNumber':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        studentNumber: e.target.value,
                    };
                });
                break;

            case 'view':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        view: e.target.value,
                    };
                });
                break;

            case 'subTitle':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        subTitle: e.target.value,
                    };
                });

                break;

            case 'originalPrice':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        originalPrice: e.target.value,
                    };
                });
                break;

            case 'urlVideoIntro':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        urlVideoIntro: e.target.value,
                    };
                });
                break;

            case 'isPublish':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        isPublish: e,
                    };
                });
                break;

            case 'lessons':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        lessons: e.target.value,
                    };
                });
                break;

            case 'addPurpose':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            course: {
                                ...preProduct?.detail?.course,
                                whatYouLearn: preProduct?.detail?.course
                                    ?.whatYouLearn?.length
                                    ? [
                                          ...preProduct?.detail?.course
                                              ?.whatYouLearn,
                                          e,
                                      ]
                                    : [e],
                            },
                        },
                    };
                });
                setNewPurposeLesson('');
                break;

            case 'deletePurpose':
                setDetailProduct((preProduct) => {
                    console.log(e);
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            course: {
                                ...preProduct?.detail?.course,
                                whatYouLearn: preProduct?.detail?.course
                                    ?.whatYouLearn?.length
                                    ? preProduct?.detail?.course?.whatYouLearn.filter(
                                          (item, index) => index !== e,
                                      )
                                    : [],
                            },
                        },
                    };
                });

                break;

            case 'addRequirement':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            course: {
                                ...preProduct?.detail?.course,
                                requirements: preProduct?.detail?.course
                                    ?.requirements?.length
                                    ? [
                                          ...preProduct?.detail?.course
                                              ?.requirements,
                                          e,
                                      ]
                                    : [e],
                            },
                        },
                    };
                });
                setNewRequirement('');
                break;

            case 'deleteRequirement':
                setDetailProduct((preProduct) => {
                    console.log(e);
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            course: {
                                ...preProduct?.detail?.course,
                                requirements: preProduct?.detail?.course
                                    ?.requirements?.length
                                    ? preProduct?.detail?.course?.requirements.filter(
                                          (item, index) => index !== e,
                                      )
                                    : [],
                            },
                        },
                    };
                });

                break;

            case 'documents':
                setDetailProduct((preProduct) => {
                    console.log(e);
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            course: {
                                ...preProduct?.detail?.course,
                                documents: e,
                            },
                        },
                    };
                });

                break;

            case 'type':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        type: e,
                    };
                });

                break;

            case 'addLesson':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        lessons: preProduct?.lessons?.length
                            ? [
                                  ...preProduct.lessons,
                                  {
                                      _id: uuidv4(),
                                      ...e,
                                  },
                              ]
                            : [
                                  {
                                      _id: uuidv4(),
                                      ...e,
                                  },
                              ],
                    };
                });

                break;

            case 'updateLesson':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        lessons: preProduct?.lessons?.length
                            ? preProduct.lessons.map((item) =>
                                  item._id === e._id ? e : item,
                              )
                            : [],
                    };
                });

                break;

            case 'deleteLesson':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        lessons: preProduct.lessons.length
                            ? preProduct.lessons.filter(
                                  (item, index) => index !== e,
                              )
                            : [],
                    };
                });

                break;

            case 'changeTypeProduct':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        typeProduct: e,
                    };
                });
                break;

            case 'changeSlugCategory':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        slugCategory: e,
                    };
                });
                break;

            case 'thumbnailProduct':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        thumbnail: e,
                    };
                });
                break;

            case 'manualSetup':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            kit: {
                                ...preProduct?.detail?.kit,
                                manualSetup: e,
                            },
                        },
                    };
                });
                break;

            case 'description':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        description: e,
                    };
                });
                break;

            case 'specifications':
                setDetailProduct((preProduct) => {
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            kit: {
                                ...preProduct?.detail?.kit,
                                specifications: e,
                            },
                        },
                    };
                });
                break;

            case 'addSoftware':
                setDetailProduct((preProduct) => {
                    console.log(e);
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            kit: {
                                ...preProduct?.detail?.kit,
                                listSoftware: preProduct?.detail?.kit
                                    ?.listSoftware?.length
                                    ? [
                                          ...preProduct?.detail?.kit
                                              ?.listSoftware,
                                          e,
                                      ]
                                    : [e],
                            },
                        },
                    };
                });

                setNewSoftware({
                    name: '',
                    description: '',
                    linkDownload: '',
                });

                break;

            case 'deleteSoftware':
                setDetailProduct((preProduct) => {
                    console.log(e);
                    return {
                        ...preProduct,
                        detail: {
                            ...preProduct?.detail,
                            kit: {
                                ...preProduct?.detail?.kit,
                                listSoftware: preProduct?.detail?.kit
                                    ?.listSoftware?.length
                                    ? preProduct?.detail?.kit?.listSoftware.filter(
                                          (item, index) => index !== e,
                                      )
                                    : [],
                            },
                        },
                    };
                });

                break;

            default:
                break;
        }
    };

    const handleCloseLesson = () => {
        setOpenLesion(false);
    };

    const handleSubmitProduct = async () => {
        let urlImage = null;
        let listImages = [];
        let newProduct = detailProduct;

        if (thumbnailSelected) {
            const formData = new FormData();
            formData.append('file', thumbnailSelected);
            console.log(thumbnailSelected);
            urlImage = await uploadFileService.uploadFile(formData);
            newProduct.thumbnail = urlImage.data.data.path;
            setUrlFilePreview(undefined);
            setThumbnailSelected(undefined);
        }

        if (
            detailProduct.typeProduct !== 'course' &&
            galleryImages.length > 0
        ) {
            for (let i = 0; i < galleryImages.length; i++) {
                const item = galleryImages[i];
                if (item.file) {
                    let form = new FormData();
                    form.append('file', item.file);
                    const res = await uploadFileService.uploadFile(form);
                    URL.revokeObjectURL(item.src);
                    listImages.push({ src: res.data.data.path });
                } else {
                    listImages.push({ src: item.src });
                }
            }

            newProduct.galleryImages = listImages;
        }

        if (!detailProduct._id) {
            const data = await productService.createProduct(newProduct);
            if (
                data.data.statusCode === 200 &&
                data.data.code === 'course/create-course-success'
            ) {
                dispatch(
                    openAlert({
                        message: 'Thêm sản phẩm thành công',
                        severity: 'success',
                    }),
                );
            } else {
                dispatch(
                    openAlert({
                        message: 'Thêm sản phẩm thất',
                        severity: 'success',
                    }),
                );
            }
        } else {
            const data = await productService.updateProduct(newProduct);
            console.log('Update product', data);
            if (
                data.data.statusCode === 200 &&
                data.data.code === 'course/update-course-successs'
            ) {
                dispatch(
                    openAlert({
                        message: 'Cập nhật sản phẩm thành công',
                        severity: 'success',
                    }),
                );
            } else {
                dispatch(
                    openAlert({
                        message: 'Cập nhật sản phẩm thất',
                        severity: 'success',
                    }),
                );
            }
        }
        onClosePopUpNewProduct();
    };

    return (
        <Dialog
            open={open}
            onClose={onClosePopUpNewProduct}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogContent>
                <DialogTitle>
                    <h3>
                        {detailProduct?._id
                            ? `Chỉnh sửa sản phẩm`
                            : `Thêm sản phẩm`}
                    </h3>
                </DialogTitle>
                <Lesson
                    openLesion={openLesion}
                    handleCloseLesson={handleCloseLesson}
                    lessonSelected={lessonSelected}
                    addLesson={handleOnChangeDetailProduct}
                />
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <FindProductByCategory
                                sx={{
                                    minWidth: 200,
                                    maxHeight: 90,
                                    marginRight: '5px',
                                }}
                                handleChange={(value) => {
                                    handleOnChangeDetailProduct(
                                        value,
                                        'changeSlugCategory',
                                    );
                                }}
                                categorySelected={
                                    detailProduct?.slugCategory || ''
                                }
                                listCategories={listCategories}
                                title='Danh mục sản phẩm'
                            />
                            <FindProductByCategory
                                sx={{ minWidth: 200, maxHeight: 90 }}
                                handleChange={(value) => {
                                    handleOnChangeDetailProduct(
                                        value,
                                        'changeTypeProduct',
                                    );
                                }}
                                categorySelected={
                                    detailProduct?.typeProduct || ''
                                }
                                listCategories={listTypeProduct}
                                title='Loại sản phẩm'
                            />
                            <TextField
                                label='Title'
                                type='text'
                                size='small'
                                value={detailProduct?.title || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(e, 'title')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />
                            <TextField
                                label='Sub title'
                                type='text'
                                size='small'
                                value={detailProduct?.subTitle || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(e, 'subTitle')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Gía gốc'
                                type='number'
                                size='small'
                                value={detailProduct?.originalPrice || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(
                                        e,
                                        'originalPrice',
                                    )
                                }
                                // endAdornment={<InputAdornment position="end">VND</InputAdornment>}
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />
                            <TextField
                                label='Số lượt mua'
                                type='number'
                                size='small'
                                value={detailProduct?.studentNumber || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(
                                        e,
                                        'studentNumber',
                                    )
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />
                            <TextField
                                label='Số sao đánh giá trung bình'
                                type='number'
                                size='small'
                                value={detailProduct?.rating || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(e, 'rating')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Số lượt xem'
                                type='number'
                                size='small'
                                value={detailProduct?.view || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(e, 'view')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Link video giới thiệu'
                                type='text'
                                size='small'
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                                onChange={(e) =>
                                    handleOnChangeDetailProduct(
                                        e,
                                        'urlVideoIntro',
                                    )
                                }
                                value={detailProduct?.urlVideoIntro || ''}
                            />

                            <Box
                                sx={{
                                    border: '1px dotted black',
                                    padding: '10px',
                                    height: '25vh',
                                    marginTop: '10px',
                                }}
                            >
                                {(detailProduct?.thumbnail ||
                                    thumbnailSelected) && (
                                    <img
                                        src={
                                            urlFilePreview ||
                                            detailProduct.thumbnail
                                        }
                                        alt=''
                                        style={{
                                            height: '80%',
                                            width: '40%',
                                        }}
                                    />
                                )}
                                <input type='file' onChange={onSelectFile} />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box>
                                <h3>Thông tin mô tả sản phẩm</h3>

                                <Editor
                                    onChange={(value) => {
                                        handleOnChangeDetailProduct(
                                            value,
                                            'description',
                                        );
                                    }}
                                    value={detailProduct?.description || ''}
                                    name='editor'
                                />
                            </Box>
                        </Box>
                    </Box>
                    {(detailProduct?.typeProduct === 'course' ||
                        !detailProduct?.typeProduct) && (
                        <Box
                            sx={{
                                display: 'flex',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '50%',
                                    margin: '5px',
                                    boxSizing: 'content-box',
                                }}
                            >
                                <Box>
                                    <h4>
                                        <i>
                                            *Bạn cần những gì để đạt kết quả tốt
                                            trong khóa học:
                                        </i>
                                    </h4>
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                        }}
                                    >
                                        <TextField
                                            label='Mục đích khóa học'
                                            type='text'
                                            size='small'
                                            multiline
                                            sx={{
                                                width: '80%',
                                                marginRight: '10px',
                                            }}
                                            value={newPurposeLesson}
                                            onChange={(e) => {
                                                setNewPurposeLesson(
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                        <Button
                                            size='small'
                                            variant='contained'
                                            color='success'
                                            onClick={() => {
                                                console.log('hello');
                                                handleOnChangeDetailProduct(
                                                    newPurposeLesson,
                                                    'addPurpose',
                                                );
                                            }}
                                        >
                                            Thêm Mục Đích
                                        </Button>
                                        <Box>
                                            {(detailProduct?.typeProduct ===
                                                'course' ||
                                                !detailProduct?.typeProduct) && (
                                                <TableContainer>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell>
                                                                Mục đích
                                                            </TableCell>
                                                            <TableCell>
                                                                Action
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {detailProduct?.detail?.course?.whatYouLearn?.map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    item
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Button
                                                                                    size='small'
                                                                                    variant='contained'
                                                                                    color='warning'
                                                                                    onClick={() => {
                                                                                        handleOnChangeDetailProduct(
                                                                                            index,
                                                                                            'deletePurpose',
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Xóa
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                },
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '50%',
                                    margin: '5px',
                                    boxSizing: 'content-box',
                                }}
                            >
                                <Box>
                                    <h4>
                                        <i>*Yêu cầu khóa học: </i>
                                    </h4>
                                    <Box
                                        sx={{
                                            marginTop: '10px',
                                        }}
                                    >
                                        <TextField
                                            label='Yêu cầu khóa học'
                                            type='text'
                                            size='small'
                                            multiline
                                            sx={{
                                                width: '80%',
                                                marginRight: '10px',
                                            }}
                                            value={newRequirement}
                                            onChange={(e) =>
                                                setNewRequirement(
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <Button
                                            onClick={() => {
                                                handleOnChangeDetailProduct(
                                                    newRequirement,
                                                    'addRequirement',
                                                );
                                            }}
                                            size='small'
                                            variant='contained'
                                            color='success'
                                        >
                                            Thêm
                                        </Button>
                                        <Box>
                                            {(detailProduct?.typeProduct ===
                                                'course' ||
                                                !detailProduct?.typeProduct) && (
                                                <TableContainer>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell>
                                                                Mục đích
                                                            </TableCell>
                                                            <TableCell>
                                                                Action
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {detailProduct?.detail?.course?.requirements?.map(
                                                                (
                                                                    item,
                                                                    index,
                                                                ) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    item
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        handleOnChangeDetailProduct(
                                                                                            index,
                                                                                            'deleteRequirement',
                                                                                        );
                                                                                    }}
                                                                                    size='small'
                                                                                    variant='contained'
                                                                                    color='warning'
                                                                                >
                                                                                    Xóa
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                },
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Box
                        sx={{
                            width: '50%',
                            margin: '5px',
                            boxSizing: 'content-box',
                        }}
                    ></Box>
                    {/* Danh sách các bài học */}
                    <hr />

                    {detailProduct?.typeProduct === 'course' ||
                    !detailProduct?.typeProduct ? (
                        <Box
                            sx={{
                                margin: '5px',
                            }}
                        >
                            <Box>
                                <h3>Tài liệu khóa học(nếu có)</h3>
                                <Editor
                                    onChange={(value) => {
                                        handleOnChangeDetailProduct(
                                            value,
                                            'documents',
                                        );
                                    }}
                                    value={
                                        detailProduct?.detail?.course?.documents
                                    }
                                    name='editor'
                                />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                }}
                            >
                                <Button
                                    sx={{ marginRight: '5px' }}
                                    size='small'
                                    variant='contained'
                                    color='success'
                                    onClick={() => {
                                        setLessonSelected({
                                            titleLesson: '',
                                            urlVideo: '',
                                        });
                                        setOpenLesion(true);
                                    }}
                                >
                                    Thêm bài học
                                </Button>
                                {detailProduct?.lessons?.length > 0 && (
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Tên bài học
                                                    </TableCell>
                                                    <TableCell>
                                                        Đường dẫn video
                                                    </TableCell>
                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {detailProduct?.lessons.map(
                                                    (lesson, index) => {
                                                        return (
                                                            <TableRow
                                                                key={lesson._id}
                                                            >
                                                                <TableCell
                                                                    sx={{
                                                                        maxWidth:
                                                                            '30%',
                                                                    }}
                                                                >
                                                                    {
                                                                        lesson.titleLesson
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        lesson.urlVideo
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        sx={{
                                                                            marginRight:
                                                                                '5px',
                                                                        }}
                                                                        size='small'
                                                                        variant='contained'
                                                                        color='success'
                                                                        onClick={() => {
                                                                            setLessonSelected(
                                                                                lesson,
                                                                            );
                                                                            setOpenLesion(
                                                                                true,
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditIcon />
                                                                    </Button>

                                                                    <Button
                                                                        size='small'
                                                                        variant='contained'
                                                                        color='warning'
                                                                        onClick={() => {
                                                                            handleOnChangeDetailProduct(
                                                                                index,
                                                                                'deleteLesson',
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    },
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {!detailProduct?.lessons && (
                                    <h3>Chưa có bài học </h3>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            {/* nếu không phải là khóa học */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    boxSizing: 'content-box',
                                    marginTop: '10px',
                                }}
                            >
                                <Box>
                                    <h4>
                                        <i>*Các phần mềm để lập trình: </i>
                                    </h4>
                                    <Box
                                        sx={{
                                            marginRight: '10px',
                                        }}
                                    >
                                        <TextField
                                            label='Tên phần mềm'
                                            type='text'
                                            size='small'
                                            multiline
                                            sx={{
                                                width: '100%',
                                                marginTop: '10px',
                                            }}
                                            value={newSoftware.name}
                                            onChange={(e) => {
                                                setNewSoftware(
                                                    (prevSoftware) => ({
                                                        ...prevSoftware,
                                                        name: e.target.value,
                                                    }),
                                                );
                                            }}
                                        />
                                        <TextField
                                            label='Mô tả phần mềm'
                                            type='text'
                                            size='small'
                                            multiline
                                            sx={{
                                                width: '100%',
                                                marginTop: '10px',
                                            }}
                                            value={newSoftware.description}
                                            onChange={(e) => {
                                                setNewSoftware(
                                                    (prevSoftware) => ({
                                                        ...prevSoftware,
                                                        description:
                                                            e.target.value,
                                                    }),
                                                );
                                            }}
                                        />
                                        <TextField
                                            label='Link tải phần mềm'
                                            type='text'
                                            size='small'
                                            multiline
                                            sx={{
                                                width: '100%',
                                                marginTop: '10px',
                                            }}
                                            value={newSoftware.linkDownload}
                                            onChange={(e) => {
                                                setNewSoftware(
                                                    (prevSoftware) => ({
                                                        ...prevSoftware,
                                                        linkDownload:
                                                            e.target.value,
                                                    }),
                                                );
                                            }}
                                        />

                                        <Button
                                            size='small'
                                            variant='contained'
                                            color='success'
                                            onClick={() => {
                                                console.log(newSoftware);
                                                handleOnChangeDetailProduct(
                                                    newSoftware,
                                                    'addSoftware',
                                                );
                                            }}
                                        >
                                            Thêm phần mềm
                                        </Button>
                                        <Box>
                                            <TableContainer>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            Tên phần mềm
                                                        </TableCell>
                                                        <TableCell>
                                                            Mô tả phần mềm
                                                        </TableCell>
                                                        <TableCell>
                                                            Link tải
                                                        </TableCell>
                                                        <TableCell>
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableBody>
                                                        {detailProduct?.detail?.kit?.listSoftware?.map(
                                                            (item, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.description
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                item.linkDownload
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Button
                                                                                size='small'
                                                                                variant='contained'
                                                                                color='warning'
                                                                                onClick={() => {
                                                                                    handleOnChangeDetailProduct(
                                                                                        index,
                                                                                        'deleteSoftware',
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Xóa
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width: '50%',
                                    }}
                                >
                                    <Button
                                        component='label'
                                        variant='contained'
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Thêm ảnh slide mô tả sản phẩms
                                        <VisuallyHiddenInput
                                            onChange={addImageGallery}
                                            type='file'
                                        />
                                    </Button>
                                    <ImageList sx={{ width: 300, height: 300 }}>
                                        {galleryImages?.map((item, index) => (
                                            <ImageListItem key={index}>
                                                <img
                                                    src={`${item.src}`}
                                                    srcSet={`${item.src}`}
                                                    loading='lazy'
                                                    alt={`${item.src}`}
                                                />
                                                <ImageListItemBar
                                                    // title={item.title}
                                                    // subtitle={item.author}
                                                    actionIcon={
                                                        <IconButton
                                                            sx={{
                                                                color: 'rgba(255, 255, 255, 0.54)',
                                                            }}
                                                            onClick={() => {
                                                                setGalleryImages(
                                                                    (
                                                                        preGalleryImages,
                                                                    ) =>
                                                                        preGalleryImages.filter(
                                                                            (
                                                                                item,
                                                                                i,
                                                                            ) =>
                                                                                i !==
                                                                                index,
                                                                        ),
                                                                );
                                                                if (item.file) {
                                                                    URL.revokeObjectURL(
                                                                        item.src,
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Box>
                            </Box>
                            <Box>
                                <h3>Mô tả sản phẩm</h3>
                                <Editor
                                    onChange={(value) => {
                                        handleOnChangeDetailProduct(
                                            value,
                                            'manualSetup',
                                        );
                                    }}
                                    value={
                                        detailProduct?.detail?.kit?.manualSetup
                                    }
                                    name='editor'
                                />
                            </Box>
                            <Box>
                                <h3>Thông số kỹ thuật</h3>
                                <Editor
                                    onChange={(value) => {
                                        handleOnChangeDetailProduct(
                                            value,
                                            'specifications',
                                        );
                                    }}
                                    value={
                                        detailProduct?.detail?.kit
                                            ?.specifications
                                    }
                                    name='editor'
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <CheckBox
                    label='Ngừng kinh doanh sản phẩm'
                    checked={detailProduct?.notSellable || false}
                    onChange={(e) => {
                        handleOnChangeDetailProduct(
                            e.target.checked,
                            'notSellable',
                        );
                    }}
                />
                <SwitchStatus
                    label='Status'
                    checked={detailProduct?.isPublish}
                    onChange={(e) => {
                        handleOnChangeDetailProduct(
                            e.target.checked,
                            'isPublish',
                        );
                    }}
                />

                <Button
                    onClick={() => {
                        handleSubmitProduct();
                    }}
                    sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                    }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    {detailProduct?._id
                        ? `Chỉnh sửa sản phẩm`
                        : `Thêm sản phẩm`}
                </Button>
                <Button
                    onClick={onClosePopUpNewProduct}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Hủy
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default memo(NewProduct);
