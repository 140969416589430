import instanceAxios from '../../helper/axios.config';
const URL_SLIDER = 'admin/sliders';

const getAllSliders = () => {
    return instanceAxios.get(URL_SLIDER);
};

const deleteSlider = (id) => {
    return instanceAxios.delete(`${URL_SLIDER}/${id}`);
};

const createSlider = (newSlider) => {
    return instanceAxios.post(URL_SLIDER, newSlider);
};

const updateSlider = (sliderUpdated) => {
    return instanceAxios.put(URL_SLIDER, sliderUpdated);
};

const sliderService = {
    getAllSliders,
    deleteSlider,
    createSlider,
    updateSlider,
};

export default sliderService;
