import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import DetailVoucher from './DetailVoucher';
import voucherService from 'services/voucher/voucher.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import CreateVoucherFast from './CreateVoucherFast';
import { InputBase, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Voucher = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [openVoucherFast, setOpenVoucherFast] = useState(false);
    const [listVouchers, setListVouchers] = useState([]);
    const [keyWords, setKeyWords] = useState('');
    const [selectedVoucher, setSelectedVoucher] = useState({
        discount: '',
        numberOfUses: '',
        startDate: '',
        endDate: '',
        discountCode: '',
    });
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [rowCountState, setRowCountState] = useState(1);

    const getAllVouchers = async (q = '', page, pageSize) => {
        try {
            setIsLoading(true);
            const vouchers = await voucherService.getAllVouchers(
                q,
                page + 1,
                pageSize,
            );
            setListVouchers(vouchers.data.data.data);
            setRowCountState(
                vouchers?.data?.data?.paginationInfo?.totalDocument,
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitFormSearch = async (e) => {
        console.log(e.target.value);
        getAllVouchers(
            e.target.value,
            paginationModel.page,
            paginationModel.pageSize,
        );
    };
    const handleDeleteVoucher = async (id) => {
        const res = await voucherService.deleteVoucher(id);
        if (res.data.code === 'voucher/delete-voucher-success') {
            dispatch(
                openAlert({
                    message: 'Xoá voucher thành công',
                    severity: 'success',
                }),
            );
            getAllVouchers();
        } else {
            dispatch(
                openAlert({
                    message: 'Xoá voucher thất bại',
                    severity: 'error',
                }),
            );
        }
        console.log(res);
    };

    useEffect(() => {
        getAllVouchers('', paginationModel.page, paginationModel.pageSize);
    }, [
        openDetail,
        openVoucherFast,
        paginationModel.page,
        paginationModel.pageSize,
    ]);

    const columns = [
        {
            field: 'discountCode',
            headerName: 'Mã khuyến mãi',
            flex: 0.5,
        },
        {
            field: 'discount',
            headerName: 'Số tiền giảm giá',
            flex: 0.5,
        },
        {
            field: 'startDate',
            headerName: 'Ngày bắt đầu',
            flex: 0.4,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            field: 'endDate',
            headerName: 'Ngày kết thúc',
            flex: 0.5,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            field: 'numberOfUses',
            headerName: 'Lượt sử dụng còn lại',
            flex: 0.5,
            renderCell: (params) => {
                const value = params.value;
                return (
                    <Chip label={value} color={value ? 'success' : 'error'} />
                );
            },
        },
        {
            field: 'status',
            headerName: 'Trạng thái',
            flex: 0.5,
            renderCell: (params) => {
                const voucher = params.row;
                const startDate = new Date(voucher.startDate);
                const endDate = new Date(voucher.endDate);
                const now = new Date();
                console.log(voucher);
                const status = now > startDate && now < endDate ? true : false;

                return (
                    <Chip
                        label={
                            status
                                ? voucher.numberOfUses
                                    ? 'Còn hạn'
                                    : 'Hết lượt sử dụng'
                                : 'Hết hạn'
                        }
                        color={
                            status && voucher.numberOfUses > 0
                                ? 'success'
                                : 'error'
                        }
                    />
                );
            },
        },
        {
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => (
                <strong>
                    {/* {params.value.getFullYear()} */}
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            setSelectedVoucher(props.row);
                            setOpenDetail(true);
                            console.log('Voucher ', props.row);
                        }}
                    >
                        Chi Tiết
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        color='warning'
                        onClick={() => {
                            console.log('DELETE: ', props.row._id);
                            handleDeleteVoucher(props.row._id);
                        }}
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Xóa
                    </Button>
                </strong>
            ),
        },
    ];

    const handleClose = () => {
        setOpenDetail(false);
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box m='0rem 0.5rem 0.5rem 0.5rem' sx={{ display: 'flex' }}>
                <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius='9px'
                    gap='3rem'
                    p='0.1rem 1.5rem'
                    width='300px'
                >
                    <form onSubmit={handleSubmitFormSearch}>
                        <InputBase
                            placeholder='Nhập mã khuyến mãi để tìm kiếm'
                            value={keyWords}
                            onChange={(e) => {
                                handleSubmitFormSearch(e);
                                setKeyWords(e.target.value);
                            }}
                        />
                        {/* <IconButton
              type="submit"
            >
              <Search />
            </IconButton> */}
                    </form>
                </FlexBetween>
                <FlexBetween
                    sx={{
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='success'
                        onClick={() => {
                            setSelectedVoucher({
                                discount: '',
                                numberOfUses: '',
                                startDate: '',
                                endDate: '',
                                discountCode: '',
                            });
                            setOpenDetail(true);
                        }}
                    >
                        Thêm voucher
                    </Button>
                </FlexBetween>
                <FlexBetween
                    sx={{
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='success'
                        onClick={() => {
                            setOpenVoucherFast(true);
                        }}
                    >
                        Tạo voucher nhanh
                    </Button>
                </FlexBetween>
            </Box>
            <Box height='80vh'>
                <DetailVoucher
                    open={openDetail}
                    voucher={selectedVoucher}
                    handleClose={handleClose}
                />
                <CreateVoucherFast
                    open={openVoucherFast}
                    handleClose={() => {
                        setOpenVoucherFast(false);
                    }}
                />
                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={listVouchers || []}
                    columns={columns}
                    pageSizeOptions={[20, 25]}
                    paginationModel={paginationModel}
                    paginationMode='server'
                    rowCount={rowCountState}
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </Box>
    );
};

export default Voucher;
