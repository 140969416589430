import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import voucherService from 'services/voucher/voucher.service';
import { useNavigate } from 'react-router-dom';

function CreateVoucherFast(props) {
    const { open, handleClose } = props;
    const [detailVouchers, setDetailVouchers] = useState({
        discount: 0,
        numberVouchers: 0,
        startDate: '',
        endDate: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmitVoucher = async () => {
        try {
            await voucherService.createVouchers(detailVouchers);
            dispatch(
                openAlert({
                    message: 'Tạo voucher nhanh thành công',
                    severity: 'success',
                }),
            );
        } catch (error) {
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const onChangeDetailVouchers = (value, type) => {
        switch (type) {
            case 'discount':
                setDetailVouchers((predetailVouchers) => {
                    return {
                        ...predetailVouchers,
                        discount: value,
                    };
                });
                break;

            case 'numberVouchers':
                setDetailVouchers((predetailVouchers) => {
                    return {
                        ...predetailVouchers,
                        numberVouchers: value,
                    };
                });
                break;

            case 'startDate':
                setDetailVouchers((predetailVouchers) => {
                    return {
                        ...predetailVouchers,
                        startDate: value,
                    };
                });
                break;

            case 'endDate':
                setDetailVouchers((predetailVouchers) => {
                    return {
                        ...predetailVouchers,
                        endDate: value,
                    };
                });
                break;
            default:
                break;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogContent>
                <DialogTitle>Tạo voucher nhanh</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Gía trị khuyến mãi'
                                    type='text'
                                    size='medium'
                                    value={detailVouchers?.discount}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => {
                                        onChangeDetailVouchers(
                                            e.target.value,
                                            'discount',
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Số lượng mã'
                                    type='text'
                                    size='medium'
                                    value={detailVouchers?.numberVouchers}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => {
                                        onChangeDetailVouchers(
                                            e.target.value,
                                            'numberVouchers',
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        value={dayjs(detailVouchers?.startDate)}
                                        label='Ngày bắt đầu'
                                        onChange={(newValue) => {
                                            const date = new Date(newValue);
                                            onChangeDetailVouchers(
                                                `${date.getFullYear()}-${String(
                                                    date.getMonth() + 1,
                                                ).padStart(2, '0')}-${String(
                                                    date.getDate(),
                                                ).padStart(2, '0')}`,
                                                'startDate',
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        sx={{ marginLeft: '15px' }}
                                        value={dayjs(detailVouchers?.endDate)}
                                        label='Ngày kết thúc'
                                        onChange={(newValue) => {
                                            const date = new Date(newValue);
                                            onChangeDetailVouchers(
                                                `${date.getFullYear()}-${String(
                                                    date.getMonth() + 1,
                                                ).padStart(2, '0')}-${String(
                                                    date.getDate(),
                                                ).padStart(2, '0')}`,
                                                'endDate',
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmitVoucher}
                    sx={{ marginRight: '5px' }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    Thêm voucher
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateVoucherFast;
