
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button } from "@mui/material";

import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import React from 'react';
function Lesson(props) {
    const {openLesion, handleCloseLesson, lessonSelected, addLesson}  = props;
    const [detailLesson, setDetailLesson] = useState(lessonSelected);
    useEffect(()=>{
        setDetailLesson(lessonSelected)
    },[lessonSelected])
    const handleSubmitForm = (e)=>{
        const type = lessonSelected.titleLesson ? 'updateLesson' : 'addLesson';
        addLesson(detailLesson,type)
        handleCloseLesson()
    }

    return (
        <Dialog 
        open={openLesion}
        onClose={handleCloseLesson}
        maxWidth={'sm'}
        >
            <form onSubmit={(e)=>{
                e.preventDefault();
                handleSubmitForm(e)
            }}>
                <DialogContent>
                    <DialogTitle>
                        {lessonSelected?.titleLesson ?  `Chỉnh sửa bài học` : `Thêm bài học`}
                    </DialogTitle>
                    <Box
                    sx={{
                        width: '100%',
                        minWidth: 500
                    }}
                    >
                        <TextField
                            label="Tên bài học"
                            type="text"
                            size="small"
                            value={detailLesson?.titleLesson || ''}
                            onChange={(e)=>{
                                setDetailLesson((preLesson=>{
                                    return {
                                        ...preLesson,
                                        titleLesson: e.target.value
                                    }
                                }))
                            }}
                            sx={{
                                width: '100%',
                                marginTop: '10px',
                            }}
                        />
                        <TextField
                            label="Link video bài học"
                            type="text"
                            size="small"
                            value={detailLesson?.urlVideo || ''}
                            onChange={(e)=>{
                                setDetailLesson((preLesson=>{
                                    return {
                                        ...preLesson,
                                        urlVideo: e.target.value
                                    }
                                }))
                            }}
                            sx={{
                                width: '100%',
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        type='submit'
                        sx={{marginRight: '5px'}}
                        size="small" variant="contained" color="success"
                        >{lessonSelected?.titleLesson ?  `Chỉnh bài học` : `Thêm bài học`}
                    </Button>
                    <Button
                        onClick={handleCloseLesson}
                        size="small" variant="contained" color="warning"
                        >Hủy 
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default Lesson;