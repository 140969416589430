import { Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { memo, useEffect, useMemo, useState } from 'react';
import productService from '../../../services/product/product.service';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import usersService from 'services/user/user.service';

function DetailOrder(props) {
    const { open, handleClose, order } = props;
    const [detailOrder, setDetailOrder] = useState();
    useEffect(() => {
        setDetailOrder(order);
    }, [order]);
    console.log('Order', order);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogContent>
                <DialogTitle>Thông tin chi tiết đơn hàng</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Mã đơn hàng:</strong>
                                {detailOrder?._id}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Tên khách hàng:</strong>
                                {detailOrder?.createdBy?.name}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Email:</strong>
                                {detailOrder?.email}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Số điện thoại:</strong>
                                {detailOrder?.phone}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Trạng đơn hàng:</strong>
                                {detailOrder?.status}
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Ngày tạo:</strong>
                                {detailOrder?.createdAt}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Đại chỉ:</strong>
                                {detailOrder?.address}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Giảm giá:</strong>
                                {detailOrder?.discount}
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <strong>Tổng tiền:</strong>
                                {detailOrder?.total}
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: '50%',
                            margin: '5px',
                            boxSizing: 'content-box',
                        }}
                    ></Box>
                    {/* Danh sách các bài học */}
                    <hr />

                    <Box>
                        {detailOrder?.listProduct.length && (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tên sản phẩm</TableCell>
                                            <TableCell>Đơn giá</TableCell>
                                            <TableCell>Số lượng</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detailOrder?.listProduct.map(
                                            (product) => (
                                                <TableRow key={product._id}>
                                                    <TableCell>
                                                        {product.title}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.finalPrice}
                                                    </TableCell>
                                                    <TableCell>
                                                        {product.quantity}
                                                    </TableCell>
                                                </TableRow>
                                            ),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {/* <Button 
                onClick={()=>{
                    handleSubmitProduct()
                    console.log("Product", detailProduct);
                }}
                sx={{marginRight: '5px'}}
                        size="small" variant="contained" color="success"
                        >{detailProduct?._id ?  `Chỉnh sửa sản phẩm` : `Thêm sản phẩm`}
                </Button> */}
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default memo(DetailOrder);
