import React, { useState, useMemo, useRef } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/monokai.css';
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module-react';
import quillEmoji from 'quill-emoji';
import VideoResize from 'quill-video-resize-module2';
import javascript from 'highlight.js/lib/languages/javascript';
import Quill from 'quill';
import c from 'highlight.js/lib/languages/c';
import cpp from 'highlight.js/lib/languages/cpp';
import uploadFileService from 'services/upload/upload.service';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('c', c);
hljs.registerLanguage('cpp', cpp);
const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;
window.hljs = hljs;
hljs.configure(['c', 'cpp', '1c']);
const Size = Quill.import('attributors/style/size');

Size.whitelist = [
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '30px',
    '40px',
    '50px',
    '60px',
    '70px',
    '80px',
];
Quill.register(
    {
        'formats/emoji': quillEmoji.EmojiBlot,
        'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
        'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
        'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
    },
    true,
);
Quill.register('modules/ImageResize', ImageResize);
Quill.register(Size, true);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/VideoResize', VideoResize);

const formats = [
    'header',
    'link',
    'image',
    'align',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'video',
    'code-block',
    'color',
    'background',
    'emoji',
];

function Editor(props) {
    const { onChange, value } = props;
    const quillRef = useRef();
    const handlerImageInput = (e) => {
        const editor = quillRef.current.getEditor();
        console.log(editor);
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const formData = new FormData();
                formData.append('file', file);
                const urlImage = await uploadFileService.uploadFile(formData);

                // upload data into server or aws or cloudinary
                const url = urlImage.data.data.path;
                editor.insertEmbed(editor.getSelection(), 'image', url);
            }
        };
    };

    const modules = useMemo(
        () => ({
            imageDrop: true,
            ImageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
            },
            VideoResize: {
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
                tagName: 'iframe', // iframe | video
            },
            syntax: {
                highlight: (text) => hljs.highlightAuto(text).value,
            },
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, 5, 6, 0] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                        { align: '' },
                        { align: 'center' },
                        { align: 'right' },
                        { align: 'justify' },
                    ],
                    [{ indent: '-1' }, { indent: '+1' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        {
                            color: [
                                '#000000',
                                '#e60000',
                                '#ff9900',
                                '#ffff00',
                                '#008a00',
                                '#0066cc',
                                '#9933ff',
                                '#ffffff',
                                '#facccc',
                                '#ffebcc',
                                '#ffffcc',
                                '#cce8cc',
                                '#cce0f5',
                                '#ebd6ff',
                                '#bbbbbb',
                                '#f06666',
                                '#ffc266',
                                '#ffff66',
                                '#66b966',
                                '#66a3e0',
                                '#c285ff',
                                '#888888',
                                '#a10000',
                                '#b26b00',
                                '#b2b200',
                                '#006100',
                                '#0047b2',
                                '#6b24b2',
                                '#444444',
                                '#5c0000',
                                '#663d00',
                                '#666600',
                                '#003700',
                                '#002966',
                                '#3d1466',
                            ],
                        },
                        { background: [] },
                    ],
                    ['code-block'],
                    ['Font'],
                    ['emoji'],
                    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                ],
                handlers: {
                    image: handlerImageInput,
                    color: function (value) {
                        if (value === 'custom-color')
                            value = window.prompt('Enter Hex Color Code');
                        this.quill.format('color', value);
                    },
                },

                'emoji-toolbar': true,
                'emoji-textarea': true,
                'emoji-shortname': true,
            },
        }),
        [],
    );

    //  ref={quillRef} value={value} modules={modules} onChange={setValue}

    return (
        <div>
            <ReactQuill
                theme='snow'
                ref={quillRef}
                formats={formats}
                modules={modules}
                onChange={(e) => {
                    onChange(e);
                }}
                value={value}
            />
        </div>
    );
}

export default Editor;
