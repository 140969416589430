import instanceAxios from '../../helper/axios.config';
const URL_FLASH_SALE = 'admin/flash-sale';

const getAllFlashSale = (keywords = '', startDate = '', endDate) => {
    return instanceAxios.get(
        `${URL_FLASH_SALE}?keywords=${keywords}&startDate=${startDate}&endDate=${endDate}`,
    );
};

const updateFlashSale = (flashSale) => {
    return instanceAxios.put(URL_FLASH_SALE, flashSale);
};

const deleteFlashSale = (id) => {
    // admin-orders
    return instanceAxios.delete(`${URL_FLASH_SALE}/${id}`);
};

const createFlashSale = (newFlashSale) => {
    return instanceAxios.post(URL_FLASH_SALE, newFlashSale);
};

const getDetailFlashSale = () => {
    return instanceAxios.get(URL_FLASH_SALE);
};

const orderService = {
    getAllFlashSale,
    createFlashSale,
    updateFlashSale,
    getDetailFlashSale,
    deleteFlashSale,
};

export default orderService;
