import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import categoryServicePost from 'services/categoryPost/categoryPost.service';
import SwitchStatus from 'components/SwitchStatus';
import { useNavigate } from 'react-router-dom';

function NewCategoryPost(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { open, handleClose, category } = props;
    const [detailCategory, setDetailCategory] = useState(category);

    useEffect(() => {
        setDetailCategory(category);
    }, [category]);
    const createCategory = async () => {
        try {
            if (detailCategory?._id) {
                const res = await categoryServicePost.updateCategoryPost(
                    detailCategory,
                );
                setDetailCategory({
                    title: '',
                    description: '',
                    sortOrder: 100,
                    status: true,
                });
                handleClose();
                dispatch(
                    openAlert({
                        message: 'Cập nhật danh mục bài viết thành công',
                        severity: 'success',
                    }),
                );
            } else {
                const res = await categoryServicePost.createCategoryPost(
                    detailCategory,
                );
                setDetailCategory({
                    title: '',
                    description: '',
                    sortOrder: 100,
                    status: true,
                });
                handleClose();
                dispatch(
                    openAlert({
                        message: 'Tạo danh bài viết mục thành công',
                        severity: 'success',
                    }),
                );
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const handleChangeCategory = (key, value) => {
        switch (key) {
            case 'title':
                setDetailCategory((preCategory) => ({
                    ...preCategory,
                    title: value,
                }));
                break;

            case 'description':
                setDetailCategory((preCategory) => ({
                    ...preCategory,
                    description: value,
                }));
                break;

            case 'sortOrder':
                setDetailCategory((preCategory) => ({
                    ...preCategory,
                    sortOrder: value,
                }));
                break;

            case 'status':
                setDetailCategory((preCategory) => ({
                    ...preCategory,
                    status: value,
                }));
                break;
            default:
                break;
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'sm'}
        >
            <DialogContent>
                <DialogTitle>Thông tin chi tiết danh mục baif viết</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Tên danh mục bài viết'
                                    type='text'
                                    size='medium'
                                    value={detailCategory?.title}
                                    onChange={(e) =>
                                        handleChangeCategory(
                                            'title',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Mô tả danh mục bài viết'
                                    type='text'
                                    size='medium'
                                    value={detailCategory?.description}
                                    onChange={(e) =>
                                        handleChangeCategory(
                                            'description',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Thứ tự hiển thị'
                                    type='number'
                                    size='medium'
                                    value={detailCategory?.sortOrder}
                                    onChange={(e) =>
                                        handleChangeCategory(
                                            'sortOrder',
                                            e.target.value,
                                        )
                                    }
                                    sx={{
                                        width: '50%',
                                    }}
                                />

                                <Box
                                    sx={{
                                        marginLeft: '10px',
                                    }}
                                >
                                    <SwitchStatus
                                        label='Trạng thái'
                                        checked={detailCategory?.status}
                                        onChange={(e) => {
                                            handleChangeCategory(
                                                'status',
                                                e.target.checked,
                                            );
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        createCategory();
                    }}
                    sx={{ marginRight: '5px' }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    {detailCategory._id
                        ? 'Cập nhật danh mục'
                        : 'Thêm danh mục sản phẩm'}
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewCategoryPost;
