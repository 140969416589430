import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import NewFlashSale from './NewFlashSale';

import flashSaleService from 'services/flashSale/flashSale.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import { InputBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FlashSale = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [selectedFlashSale, setSelectedFlashSale] = useState({
        title: '',
        listProducts: [],
        isPublic: true,
        startDate: '',
        endDate: '',
    });
    const [listFlashSale, setListFlashSale] = useState([]);
    const [keyWords, setKeyWords] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState(false);

    const getAllCodeForKit = async (q = '') => {
        try {
            setIsLoading(true);
            const flashSales = await flashSaleService.getAllFlashSale(q);
            setListFlashSale(flashSales.data.data);
        } catch (error) {
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitFormSearch = async (e) => {
        console.log(e.target.value);
        getAllCodeForKit(e.target.value);
    };
    const handleDeleteFlashSale = async (id) => {
        try {
            const res = await flashSaleService.deleteFlashSale(id);
            if (res.data.code === 'flashSale/delete-success') {
                dispatch(
                    openAlert({
                        message: 'Xoá chương trình flash sale thành công',
                        severity: 'success',
                    }),
                );
                getAllCodeForKit();
            } else {
                throw new Error('Delete flash sale error');
            }
        } catch (error) {
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        getAllCodeForKit();
    }, [openDetail]);

    const columns = [
        {
            field: 'title',
            headerName: 'Tiêu đề flash sale',
            flex: 0.5,
        },
        {
            field: 'startDate',
            headerName: 'Ngày bắt đầu',
            flex: 0.4,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            field: 'endDate',
            headerName: 'Ngày kết thúc',
            flex: 0.4,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            field: 'status',
            headerName: 'Trạng thái',
            flex: 0.4,
            renderCell: (params) => {
                const flashSale = params.row;
                const startDate = new Date(flashSale.startDate);
                const endDate = new Date(flashSale.endDate);
                const now = new Date();
                const status =
                    now > startDate && now < endDate
                        ? flashSale.isPublic
                            ? true
                            : false
                        : false;
                console.log('startDate', startDate.getTime());
                console.log('endDate', endDate.getTime());
                console.log('now', now.getTime());

                return (
                    <Chip
                        label={status ? 'Đang chay' : 'Không hoạt động'}
                        color={status ? 'success' : 'error'}
                    />
                );
            },
        },
        {
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => (
                <strong>
                    <Button
                        variant='contained'
                        size='small'
                        color='warning'
                        onClick={() => {
                            console.log('DELETE: ', props.row._id);
                            handleDeleteFlashSale(props.row._id);
                        }}
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Xóa
                    </Button>
                    <Button
                        variant='contained'
                        size='small'
                        color='warning'
                        onClick={() => {
                            setSelectedFlashSale(props.row);
                            setOpenDetail(true);
                        }}
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Chi tiết
                    </Button>
                </strong>
            ),
        },
    ];

    const handleClose = () => {
        setOpenDetail(false);
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box m='0rem 0.5rem 0.5rem 0.5rem' sx={{ display: 'flex' }}>
                <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius='9px'
                    gap='3rem'
                    p='0.1rem 1.5rem'
                    width='300px'
                >
                    <form onSubmit={handleSubmitFormSearch}>
                        <InputBase
                            placeholder='Nhập mã khuyến mãi để tìm kiếm'
                            value={keyWords}
                            onChange={(e) => {
                                handleSubmitFormSearch(e);
                                setKeyWords(e.target.value);
                            }}
                        />
                        {/* <IconButton
              type="submit"
            >
              <Search />
            </IconButton> */}
                    </form>
                </FlexBetween>
                <FlexBetween
                    sx={{
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='success'
                        onClick={() => {
                            setSelectedFlashSale({
                                title: '',
                                listProducts: [],
                                isPublic: true,
                                startDate: '',
                                endDate: '',
                            });
                            setOpenDetail(true);
                        }}
                    >
                        Thêm đợt flash sale
                    </Button>
                </FlexBetween>
            </Box>
            <Box height='80vh'>
                <NewFlashSale
                    open={openDetail}
                    flashSaleProp={selectedFlashSale}
                    handleClose={handleClose}
                />

                <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    rows={listFlashSale || []}
                    columns={columns}
                    pageSizeOptions={[20, 25]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />
            </Box>
        </Box>
    );
};

export default FlashSale;
