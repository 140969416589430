import React, { useEffect, useMemo, useState } from 'react';
// import FlexBetween from 'components/FlexBetween';
import Header from 'components/Header';
// import { DownloadOutlined } from '@mui/icons-material';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import OverviewChart from 'components/OverviewChart';
import revenueService from '../../services/revenue/revenue.service';
import { formatCurrency } from '../../helper/currencyHelper';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import BarChart from 'components/BarChart';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
const columns = [
    {
        field: 'productName',
        headerName: 'Tên sản phẩm',
        flex: 1,
    },
    {
        field: 'totalQuantity',
        headerName: 'Số lượng đã bán',
        flex: 1,
    },
    {
        field: 'totalRevenue',
        headerName: 'Gía trị sản phẩm đã bán',
        flex: 0.5,
        sortable: false,
        renderCell: (params) => formatCurrency(params.value),
    },
];

function Statistical() {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isNonMediumScreens = useMediaQuery('(min-width: 1200px)');
    const [yearSelected, setYearSelected] = useState(new Date());
    const [
        totalStatisticalMonthOfCurrentYear,
        setTotalStatisticalMonthOfCurrentYear,
    ] = useState([]);
    const [totalStatisticalCurrentYear, setTotalStatisticalCurrentYear] =
        useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [topProductBestSeller, setTopProductBestSeller] = useState();

    const getRevenue12Days = async () => {
        try {
            const res = await revenueService.getRevenueByDate();
            setTotalSales([
                {
                    id: 'orders',
                    color: '#2ecc71',
                    data: res.data.data?.map((item) => ({
                        y: item.totalRevenue,
                        x: item.date,
                    })),
                },
            ]);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const getRevenueGroupYear = async () => {
        try {
            const res = await revenueService.getRevenueGroupByYear();
            setTotalStatisticalCurrentYear(
                res.data.data.map((item) => ({
                    year: item.year,
                    color: '#27ae60',
                    DT: item.totalRevenue,
                })),
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const getTopProductsBestSeller = async () => {
        try {
            const res = await revenueService.getTopProductsBestSeller(15);
            setTopProductBestSeller(res.data.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        getRevenue12Days();
        getRevenueGroupYear();
        getTopProductsBestSeller();
    }, []);

    useEffect(() => {
        const getRevenueGroupMonth = async () => {
            try {
                const res = await revenueService.getRevenueGroupByMonth(
                    yearSelected.getFullYear(),
                );
                setTotalStatisticalMonthOfCurrentYear(
                    res.data.data.map((item) => ({
                        month: item.month_display,
                        color: 'hsl(156, 70%, 50%)',
                        DT: item.totalRevenue,
                    })),
                );
            } catch (error) {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            }
        };

        getRevenueGroupMonth();
    }, [yearSelected]);

    const generateChartRevenueMonthsOfYear = useMemo(() => {
        const tooltip = (point) => {
            return (
                <div style={{ background: '#2ecc71', borderRadius: '4px' }}>
                    <div>Tháng: {point.indexValue}</div>
                    <div>
                        Doanh thu:
                        {formatCurrency(point.value)}
                    </div>
                </div>
            );
        };
        return (
            <BarChart
                data={totalStatisticalMonthOfCurrentYear}
                keys={['DT']}
                indexBy={'month'}
                colors={['#2980b9']}
                tooltip={tooltip}
            />
        );
    }, [totalStatisticalMonthOfCurrentYear]);

    const generateChartRevenueYears = useMemo(() => {
        const tooltip = (point) => {
            return (
                <div style={{ background: '#2ecc71', borderRadius: '4px' }}>
                    <div>Năm: {point.indexValue}</div>
                    <div>
                        Doanh thu:
                        {formatCurrency(point.value)}
                    </div>
                </div>
            );
        };
        return (
            <BarChart
                data={totalStatisticalCurrentYear}
                keys={['DT']}
                indexBy={'year'}
                colors={['#9b59b6']}
                tooltip={tooltip}
            />
        );
    }, [totalStatisticalCurrentYear]);
    return (
        <Box m='1.5rem 2.5rem'>
            {/* <FlexBetween>
                <Box>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: '#e0e0e0',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            padding: '10px 20px',
                        }}
                    >
                        <a href='/frmofmro'>Welcome to your dashboard</a>
                        <DownloadOutlined sx={{ mr: '10px' }} />
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween> */}

            <Box
                mt='10px'
                display='grid'
                gridTemplateColumns='repeat(12, 1fr)'
                gridAutoRows='160px'
                gap='10px'
                sx={{
                    '& > div': {
                        gridColumn: isNonMediumScreens ? undefined : 'span 12',
                    },
                }}
            >
                <Box
                    gridColumn='span 12'
                    gridRow='span 2'
                    backgroundColor='#ecf0f1'
                    p='2rem'
                    borderRadius='0.55rem'
                >
                    <Header subtitle='Doanh thu 15 ngày gần đây có đơn hàng' />
                    <OverviewChart
                        view='sales'
                        isDashboard={true}
                        data={totalSales}
                    />
                </Box>

                {/* ROW 2 */}
                <Box
                    gridColumn='span 12'
                    gridRow='span 3'
                    backgroundColor='#ecf0f1'
                    p='1rem'
                    borderRadius='0.55rem'
                    alignContent='center'
                    marginTop='20px'
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={'"Doanh thu các tháng trong năm"'}
                            views={['year']}
                            value={dayjs(yearSelected)}
                            onChange={(value) =>
                                setYearSelected(new Date(value))
                            }
                        />
                    </LocalizationProvider>
                    {generateChartRevenueMonthsOfYear}
                </Box>
                <Box
                    gridColumn='span 12'
                    gridRow='span 3'
                    backgroundColor='#ecf0f1'
                    p='1rem'
                    borderRadius='0.55rem'
                    alignContent='center'
                    marginTop='20px'
                >
                    {generateChartRevenueYears}
                </Box>
                <Box
                    gridColumn='span 12'
                    gridRow='span 3'
                    sx={{
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            borderRadius: '5rem',
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#e0e0e0',
                            color: theme.palette.secondary[100],
                            borderBottom: 'none',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: '#e0e0e0',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#e0e0e0',
                            color: theme.palette.secondary[100],
                            borderTop: 'none',
                        },
                        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <Header subtitle='Top các sản phẩm bán chạy nhất' />
                    {!topProductBestSeller ? (
                        <div>data</div>
                    ) : (
                        <DataGrid
                            loading={!topProductBestSeller}
                            getRowId={(row) => row._id}
                            rows={topProductBestSeller || []}
                            columns={columns}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default Statistical;
