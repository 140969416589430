import Snackbar from '@mui/material/Snackbar';
import MuiAlert  from '@mui/material/Alert';
import { useDispatch, useSelector } from "react-redux";
import * as React from 'react';
import { closeAlert } from 'slices/alert/alertSlice';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function AlertMessage() {
    const { status, message, severity} = useSelector((state) => state.alert);
    const dispatch = useDispatch();
    function handleCloseAlert()
    {
        dispatch(closeAlert())
    }
    return ( 
    <Snackbar open={status} autoHideDuration={4000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
         <Alert onClose={handleCloseAlert} severity={severity} sx={{ width: '100%' }}>
            {message}
        </Alert>
    </Snackbar> 
    );
}

export default AlertMessage;