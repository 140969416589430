import React, { useCallback, useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import orderService from 'services/orders/order.service';
import FormatDate from 'components/FormatDate';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import DetailOrder from './DetailOrder';
import FlexBetween from 'components/FlexBetween';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import style from './style.orders.css';
import { InputBase, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
const listStatusOrders = [
    {
        value: 'pending',
        label: 'Đang xử lý',
    },
    {
        value: 'completed',
        label: 'Hoàn thành',
    },
    {
        value: 'cancelled',
        label: 'Đã hủy',
    },
    {
        value: 'shipping',
        label: 'Đang giao',
    },
    {
        value: 'all',
        label: 'Tất cả',
    },
];
const Orders = () => {
    const [selectedDate, setSelectedDate] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [detailOrder, setDetailOrder] = useState();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [keyWords, setKeyWords] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const getAllOrders = async (
        keyWords = '',
        date = '',
        status = '',
        pageNum,
        pageSize,
    ) => {
        try {
            setIsLoading(true);
            const res = await orderService.getAllOrders(
                keyWords,
                date,
                status,
                pageNum,
                pageSize,
            );
            setOrders(res.data.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const deleteOrder = async (id) => {
        try {
            await orderService.deleteOrder(id);
            getAllOrders(null, null, null, page + 1, rowsPerPage);
            dispatch(
                openAlert({
                    message: 'Xóa đơn hàng thành công',
                    severity: 'success',
                }),
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    useEffect(() => {
        if (selectedDate) {
            const createAt = new Date(selectedDate);
            if (
                createAt.getFullYear() &&
                createAt.getMonth() &&
                createAt.getDate()
            ) {
                getAllOrders(
                    keyWords,
                    `${createAt.getFullYear()}-${String(
                        createAt.getMonth() + 1,
                    ).padStart(2, '0')}-${String(createAt.getDate()).padStart(
                        2,
                        '0',
                    )}`,
                    '',
                    page + 1,
                    rowsPerPage,
                );
            }
        } else {
            getAllOrders(keyWords, '', statusFilter, page + 1, rowsPerPage);
        }
    }, [selectedDate, statusFilter, page, rowsPerPage]);

    const handleSubmitFormSearch = async (e) => {
        e.preventDefault();
        getAllOrders(keyWords, null, null, page + 1, rowsPerPage);
    };

    const handleClose = useCallback(() => {
        setOpenDetail(false);
    }, []);

    const updateStatusOrder = async (idOrder, status) => {
        try {
            await orderService.updateStatusOrders(idOrder, status);
            dispatch(
                openAlert({
                    message: 'Cập nhật đơn hàng thành công',
                    severity: 'success',
                }),
            );
            getAllOrders(null, null, null, page + 1, rowsPerPage);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <Box>
                <Box m='0rem 0.5rem 0.5rem 0.5rem' sx={{ display: 'flex' }}>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius='9px'
                        gap='3rem'
                        p='0.1rem 1.5rem'
                    >
                        <form onSubmit={handleSubmitFormSearch}>
                            <InputBase
                                placeholder='Nhập số điện thoại'
                                value={keyWords}
                                onChange={(e) => {
                                    getAllOrders(
                                        e.target.value,
                                        null,
                                        null,
                                        page + 1,
                                        rowsPerPage,
                                    );
                                    setKeyWords(e.target.value);
                                }}
                                sx={{
                                    width: '300px',
                                }}
                            />
                            <IconButton type='submit'>
                                <Search />
                            </IconButton>
                        </form>
                    </FlexBetween>
                    <FlexBetween
                        sx={{
                            marginLeft: '30px',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label='Ngày tạo đơn hàng'
                                onChange={(newValue) => {
                                    console.log(newValue);
                                    setSelectedDate(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </FlexBetween>
                    <FlexBetween
                        sx={{
                            marginLeft: '30px',
                        }}
                    >
                        <FormControl sx={{ minWidth: 200 }} size='md'>
                            <InputLabel id='demo-select-small-label'>
                                Trạng thái
                            </InputLabel>
                            <Select
                                labelId='demo-select-small-label'
                                id='demo-select-small'
                                value={statusFilter}
                                // label={statusFilter.label}
                                onChange={(e) => {
                                    setStatusFilter(e.target.value || '');
                                }}
                                classes={style['status-order-success']}
                            >
                                {listStatusOrders.map((item) => (
                                    <MenuItem
                                        color='success'
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FlexBetween>
                </Box>
            </Box>
            <Box>
                <DetailOrder
                    open={openDetail}
                    order={detailOrder}
                    handleClose={handleClose}
                />
                <Paper sx={{ width: '100%', m: 1 }}>
                    {isLoading && (
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {!isLoading && (
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Tên khách hàng
                                            </TableCell>
                                            <TableCell align='center'>
                                                Mã đơn hàng
                                            </TableCell>
                                            <TableCell align='center'>
                                                Số điện thoại
                                            </TableCell>
                                            <TableCell align='center'>
                                                Email
                                            </TableCell>
                                            <TableCell align='center'>
                                                Ngày tạo
                                            </TableCell>
                                            <TableCell align='center'>
                                                Tổng tiền
                                            </TableCell>
                                            <TableCell align='center'>
                                                Trạng thái
                                            </TableCell>
                                            <TableCell align='center'>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders?.listOrders?.map((order) => (
                                            <TableRow key={order._id}>
                                                <TableCell>
                                                    {order?.customerName ||
                                                        order?.createdBy.name}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {order?._id}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {order.phone}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {order.email}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <FormatDate
                                                        date={order.createdAt}
                                                    />
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {order.total}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 120,
                                                            color: 'success',
                                                        }}
                                                        size='small'
                                                        disabled={
                                                            order.status ===
                                                                'completed' ||
                                                            order.status ===
                                                                'cancelled'
                                                        }
                                                    >
                                                        <InputLabel id='demo-select-small-label'>
                                                            Trạng thái
                                                        </InputLabel>
                                                        <Select
                                                            labelId='demo-select-small-label'
                                                            id='demo-select-small'
                                                            value={order.status}
                                                            label={order.status}
                                                            onChange={(e) => {
                                                                updateStatusOrder(
                                                                    order._id,
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                            classes={
                                                                style[
                                                                    'status-order-success'
                                                                ]
                                                            }
                                                        >
                                                            {listStatusOrders
                                                                .filter(
                                                                    (status) =>
                                                                        status.value !==
                                                                        'all',
                                                                )
                                                                .map((item) => (
                                                                    <MenuItem
                                                                        color='success'
                                                                        key={
                                                                            item.value
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                        onClick={() => {
                                                            setDetailOrder(
                                                                order,
                                                            );
                                                            setOpenDetail(true);
                                                        }}
                                                    >
                                                        Chi Tiết
                                                    </Button>
                                                    <Button
                                                        variant='contained'
                                                        size='small'
                                                        sx={{
                                                            marginLeft: '3px',
                                                            marginTop: '3px',
                                                        }}
                                                        onClick={() => {
                                                            deleteOrder(
                                                                order._id,
                                                            );
                                                        }}
                                                    >
                                                        Xóa
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component='div'
                                count={Number(
                                    orders?.paginationInfo?.totalDocument || 0,
                                )}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default Orders;
