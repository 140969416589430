import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import categoryService from 'services/category/category.service';
import productService from 'services/product/product.service';
import NewProduct from './NewProduct';
import FindProductByCategory from 'components/FindProductByCategory';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import { useNavigate } from 'react-router-dom';

const DEFAULT_CATEGORY = {
    slug: 'default-category',
    title: 'Chưa phân loại',
};

const renderStatusAccount = (props) => {
    const { value } = props;
    return (
        <>
            <Chip
                label={value ? 'Hoạt động' : 'Coming soon'}
                color={value ? 'success' : 'error'}
            />
        </>
    );
};
const Product = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categorySelected, setCategorySelected] = useState('');
    const [listCategories, setListCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [productSelected, setProductSelected] = useState();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);
    const [rowCountState, setRowCountState] = useState(1);
    const getProductByCategory = async (type, page, pageSize) => {
        if (type) {
            try {
                setIsLoading(true);
                const res = await productService.getAllProducts(
                    type,
                    page + 1,
                    pageSize,
                );

                setProducts(res?.data?.data?.products);
                setRowCountState(
                    res?.data?.data?.paginationInfo?.totalDocument,
                );
            } catch (error) {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        categoryService
            .getAllCategory()
            .then((res) => {
                setListCategories([...res.data.data, DEFAULT_CATEGORY]);
                setCategorySelected(res.data.data[0].slug);
            })
            .catch((error) => {
                console.log(error);
                if (
                    error?.response?.data?.code === 'auth/refresh-token-invalid'
                ) {
                    dispatch(
                        openAlert({
                            message:
                                'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                            severity: 'error',
                        }),
                    );
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            });
    }, []);

    useEffect(() => {
        getProductByCategory(
            categorySelected,
            paginationModel.page,
            paginationModel.pageSize,
        );
    }, [categorySelected]);

    const handlePaginationModelChange = (newPaginationModel) => {
        getProductByCategory(
            categorySelected,
            newPaginationModel.page,
            newPaginationModel.pageSize,
        );
        setPaginationModel(newPaginationModel);
    };

    const columns = useMemo(
        () => [
            {
                id: 'title',
                field: 'title',
                headerName: 'Tên sản phẩmxxx',
                flex: 1,
            },
            {
                id: 'originalPrice',
                field: 'originalPrice',
                headerName: 'Gía bán',
                flex: 0.5,
                unit: 'đ',
            },
            {
                id: 'createdBy',
                field: 'createdBy',
                headerName: 'Người Tạo',
                flex: 0.4,
            },
            {
                id: 'isPublish',
                field: 'isPublish',
                headerName: 'Trạng thái',
                flex: 0.4,
                renderCell: renderStatusAccount,
            },
            {
                id: 'isPublish',
                field: 'action',
                headerName: 'Thao tác',
                flex: 0.5,
                renderCell: (props) => {
                    // console.log("Hoang 999999",props)
                    return (
                        <strong>
                            <Button
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    setProductSelected(props.row);
                                    setOpen(true);
                                }}
                            >
                                Chi tiết
                            </Button>

                            <Button
                                variant='contained'
                                size='small'
                                sx={{
                                    marginLeft: '5px',
                                }}
                                onClick={() => {
                                    handleDeleteProduct(props.row._id);
                                }}
                            >
                                Xóa
                            </Button>
                        </strong>
                    );
                },
            },
        ],
        [],
    );

    const handleChange = (value) => {
        setCategorySelected(value);
    };

    const handleDeleteProduct = async (_id) => {
        const res = await productService.deleteProduct(_id);
        console.log(res);
        if (res?.data?.code === 'product/delete-product-successfully') {
            getProductByCategory(categorySelected, paginationModel);
            dispatch(
                openAlert({
                    message: 'Xóa sản phẩm thành công',
                    severity: 'success',
                }),
            );
        } else {
            dispatch(
                openAlert({
                    message: 'Xóa sản phẩm thất bại',
                    severity: 'error',
                }),
            );
        }
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box>
                <FindProductByCategory
                    sx={{ m: 1, minWidth: 200, maxHeight: 90, padding: '5px' }}
                    handleChange={handleChange}
                    categorySelected={categorySelected}
                    listCategories={listCategories}
                />
                <Button
                    onClick={() => {
                        setProductSelected({
                            title: '',
                            subTitle: '',
                            type: '',
                            originalPrice: '',
                            discount: '',
                            finalPrice: '',
                            lessons: [],
                            description: '',
                            urlVideoIntro: '',
                            isPublish: true,
                            rating: 4.8,
                            studentNumber: 1634,
                            view: 4356,
                            detail: {
                                course: {
                                    whatYouLearn: [],
                                    requirements: [],
                                    documents: '',
                                },
                                kit: {
                                    specifications: '',
                                    manualSetup: '',
                                    listSoftware: [],
                                },
                            },
                            typeProduct: 'course',
                        });
                        setOpen(true);
                    }}
                    sx={{ m: 1, minWidth: 120, maxHeight: 90 }}
                    size='medium'
                    variant='contained'
                    color='success'
                >
                    Thêm sản phẩm
                </Button>
                <NewProduct
                    open={open}
                    listCategories={listCategories}
                    onClosePopUpNewProduct={handleClose}
                    product={productSelected}
                />
            </Box>
            <Box height='75vh'>
                <DataGrid
                    loading={isLoading}
                    rowCount={rowCountState}
                    columns={columns}
                    rows={products}
                    getRowId={(row) => row._id}
                    pageSizeOptions={[5, 10, 25]}
                    paginationMode='server'
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                />
            </Box>
        </Box>
    );
};

export default Product;
