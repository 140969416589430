import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import sliderService from '../../../services/slider/slider.service';
import uploadFileService from '../../../services/upload/upload.service';
import { useNavigate } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function DetailSlider(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { open, handleClose, slider } = props;
    const [detailSlider, setDetailSlider] = useState();
    const [imageSelected, setImageSelected] = useState();
    const [urlFilePreview, setUrlFilePreview] = useState();

    useEffect(() => {
        setDetailSlider(slider);
        setImageSelected('');
    }, [slider]);

    useEffect(() => {
        if (!imageSelected) {
            setUrlFilePreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(imageSelected);
        setUrlFilePreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [imageSelected]);

    const handleSubmitSlider = async () => {
        try {
            let urlImage;
            if (imageSelected) {
                const formData = new FormData();
                formData.append('file', imageSelected);
                console.log(imageSelected);
                urlImage = await uploadFileService.uploadFile(formData);
                setImageSelected('');
            }
            console.log(urlImage);

            if (detailSlider._id) {
                const newSlider = {
                    ...detailSlider,
                    urlImage:
                        urlImage?.data?.data?.path || detailSlider.urlImage,
                };
                const res = await sliderService.updateSlider(newSlider);
                console.log(res);
                dispatch(
                    openAlert({
                        message: 'Cập nhật slider thành công',
                        severity: 'success',
                    }),
                );
            } else {
                const newSlider = {
                    ...detailSlider,
                    urlImage: urlImage.data.data.path,
                };
                const res = await sliderService.createSlider(newSlider);
                console.log(res);
                dispatch(
                    openAlert({
                        message: 'Tạo slider thành công',
                        severity: 'success',
                    }),
                );
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const onChangeDetailSlider = (value, type) => {
        switch (type) {
            case 'title':
                setDetailSlider((preDetailSlider) => {
                    return {
                        ...preDetailSlider,
                        title: value,
                    };
                });
                break;

            case 'description':
                setDetailSlider((preDetailSlider) => {
                    return {
                        ...preDetailSlider,
                        description: value,
                    };
                });
                break;

            case 'actionLink':
                setDetailSlider((preDetailSlider) => {
                    return {
                        ...preDetailSlider,
                        actionLink: value,
                    };
                });
                break;

            default:
                break;
        }
    };

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImageSelected(undefined);
            return;
        }
        setImageSelected(e.target.files[0]);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogContent>
                <DialogTitle>
                    {detailSlider?._id ? `Thông tin slider` : `Tạo slider`}
                </DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Tiêu đề slider'
                                    type='text'
                                    size='medium'
                                    value={detailSlider?.title}
                                    onChange={(e) => {
                                        onChangeDetailSlider(
                                            e.target.value,
                                            'title',
                                        );
                                    }}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Mô tả slider'
                                    type='text'
                                    size='medium'
                                    value={detailSlider?.description}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => {
                                        onChangeDetailSlider(
                                            e.target.value,
                                            'description',
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Link đính kèm slider'
                                    type='text'
                                    size='medium'
                                    value={detailSlider?.actionLink}
                                    sx={{
                                        width: '100%',
                                    }}
                                    onChange={(e) => {
                                        onChangeDetailSlider(
                                            e.target.value,
                                            'actionLink',
                                        );
                                    }}
                                />
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                }}
                            >
                                <Box>
                                    <Button
                                        component='label'
                                        variant='contained'
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput
                                            type='file'
                                            onChange={(e) => {
                                                onSelectFile(e);
                                            }}
                                        />
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        marginLeft: '20px',
                                    }}
                                >
                                    {(detailSlider?.urlImage ||
                                        urlFilePreview) && (
                                        <img
                                            style={{
                                                width: '300px',
                                                height: '200px',
                                            }}
                                            src={
                                                urlFilePreview ||
                                                detailSlider?.urlImage
                                            }
                                            alt=''
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmitSlider}
                    sx={{ marginRight: '5px' }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    {detailSlider?._id ? `Cập nhật slider` : `Thêm slider`}
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DetailSlider;
