import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CategoriesPost(props) {
    const { label, listCategories, listCategoriesSelected, onChange } = props;
    const [listSelected, setListSelected] = useState([]);

    useEffect(() => {
        setListSelected(listCategoriesSelected);
    }, [listCategoriesSelected]);

     const handleChange = (category) => {
            setListSelected((preSelected) => {
                const newCategory = preSelected.some((c) => c.slug === category.slug)
                    ? preSelected.filter((c) => c.slug !== category.slug)
                    : [
                          ...preSelected,
                          {
                              slug: category.slug,
                              title: category.title,
                              description: category.description,
                          },
                    ];
                onChange(newCategory,'category');
                return newCategory;
            });
            console.log(listSelected);
        };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id='demo-multiple-checkbox-label'>
                    {label}
                </InputLabel>
                <Select
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox'
                    multiple
                    value={listSelected}
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) =>
                        Object.values(selected.map((c) => c.title)).join(', ')
                    }
                    MenuProps={MenuProps}
                >
                    {listCategories.map((category) => (
                        <MenuItem
                            key={category.slug}
                            value={{
                                slug: category.slug,
                                title: category.title,
                                description: category.description,
                            }}
                        >
                            <Checkbox
                                checked={listSelected.some(
                                    (c) => c.slug === category.slug,
                                )}
                                onClick={() => {
                                    handleChange(category);
                                }}
                            />
                            <ListItemText primary={category.title} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default CategoriesPost;
