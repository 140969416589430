import instanceAxios from '../../helper/axios.config';
const URL_POST = 'admin/posts';

const getAllPost = (
    category = '',
    pageNum = 1,
    pageSize = 10000000,
    searchTerm,
) => {
    return instanceAxios.get(
        `${URL_POST}?category=${category}&pageNum=${pageNum}&pageSize=${pageSize}`,
    );
};

const getDetailPost = (slug) => {
    return instanceAxios.get(`${URL_POST}/${slug}`);
};

const deletePost = (id) => {
    return instanceAxios.delete(`${URL_POST}/${id}`);
};

const createPost = (newPost) => {
    return instanceAxios.post(URL_POST, newPost);
};
const updatePost = (post) => {
    return instanceAxios.put(URL_POST, post);
};
const productService = {
    getAllPost,
    getDetailPost,
    createPost,
    deletePost,
    updatePost,
};

export default productService;
