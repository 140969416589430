import { Box, Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import codeForKitService from "services/codeForKit/codeForKit.service";
import ListCourses from "./ListCourses";

function NewCodeForKit(props) {
    const {open, handleClose, codeForKitProp} = props;
    const [openListCourses, setOpenListCourse] = useState(false);
    const [detailCodeForKit,setDetailCodeForKit] = useState();
    const dispatch = useDispatch();
    
    useEffect(()=>{
        setDetailCodeForKit(codeForKitProp)
    },[codeForKitProp])
    
    const handleSubmitCodeForKit = async ()=>{
        console.log("handleSubmitCodeForKit",detailCodeForKit)
        const res = await codeForKitService.createCodeForKitFast(detailCodeForKit);
        console.log(res)
        if(res.data.code === 'codeForKit/create-success')
        {
            dispatch(openAlert({
                message: 'Tạo mã kit thành công',
                severity: 'success',
            }));
            handleClose();
        }
        else{
            
            dispatch(openAlert({
                message: 'Tạo mã kit thất bại',
                severity: 'error',
            }));
        }
    }

    const onChangeDetailCodeForKit = (value, type)=>{
        switch (type) {
            case 'numberOfCode':
                setDetailCodeForKit((preDetailCodeForKit)=>{
                    return{
                        ...preDetailCodeForKit,
                        numberOfCode: value,
                    }
                })
                break;
            
            case 'detailCourse':
                setDetailCodeForKit((preDetailCodeForKit)=>{
                    return{
                        ...preDetailCodeForKit,
                        _idCourse: value._id,
                        detailCourse: {
                            title: value.title,
                            typeProduct: value.typeProduct,
                            slug: value.slug
                        }
                    }
                })
                break;
    
            default:
                break;
        }
    }

    return ( 
        <Dialog 
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'sm'}
        >
            <DialogContent 
            >
                <DialogTitle>
                    Thông tin mã
                </DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                            width: '100%',
                            margin: '5px',
                            boxSizing: 'content-box'
                            }}
                        >
                          <ListCourses
                            open={openListCourses}
                            handleClose={()=>{
                                setOpenListCourse(false)
                            }}
                            setDetailCodeForKit={(detailCourse)=>{
                                onChangeDetailCodeForKit(detailCourse,'detailCourse')
                            }}
                          />
                            <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                            <TextField
                                label="Số lượng mã "
                                type="text"
                                size="medium"
                                value = {detailCodeForKit?.numberOfCode}
                                onChange={(e)=>{
                                    onChangeDetailCodeForKit(e.target.value,'numberOfCode');
                                }}
                                sx={{
                                    width: '100%'
                                }}
                                />
                            </Box>
                             <Box sx={{
                            marginTop: '10px',
                            display: 'flex',
                            }}>
                                Khóa học được chọn: {detailCodeForKit?._idCourse ? detailCodeForKit?.detailCourse?.title : 'Chưa chọn khóa học'}
                            </Box>

                        </Box>
                    </Box>
                   
                    
                  
                </Box>
            </DialogContent>
            <DialogActions>
                 <Button
                    onClick={()=>{
                        setOpenListCourse(true)
                    }}
                    size="small" variant="contained" color="warning"
                    >Chọn khóa học 
                </Button>
                <Button 
                onClick={handleSubmitCodeForKit}
                disabled={!detailCodeForKit?._idCourse && !detailCodeForKit?.numberOfCode}
                sx={{marginRight: '5px'}}
                size="small" variant="contained" color="success"
                
                >
                    Thêm sản mã
                </Button>
                <Button
                    onClick={handleClose}
                    size="small" variant="contained" color="warning"
                    >Đóng 
                </Button>
               
            </DialogActions>
        </Dialog>
    );
}

export default NewCodeForKit;