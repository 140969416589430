import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from '@mui/x-data-grid';
import NewCodeForKit from './NewCodeForKit';
import codeForKitService from 'services/codeForKit/codeForKit.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import FlexBetween from 'components/FlexBetween';
import { InputBase } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import DropDownFilter from 'components/DropDownFilter';
// const LIST_OPTIONS_STATUS = [
//     {
//         key: 'all',
//         valueKey: '',
//         titleOption: 'Tất cả',
//     },
//     {
//         key: 'false',
//         valueKey: false,
//         titleOption: 'Đã dùng',
//     },
//     {
//         key: 'true',
//         valueKey: true,
//         titleOption: 'Chưa dùng',
//     },
// ];

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarExport
                sx={{ color: 'black' }}
                csvOptions={{
                    fileName: 'Mã code khóa học',
                    utf8WithBom: true,
                }}
            />
        </GridToolbarContainer>
    );
};

const CodeForKit = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const [openDetail, setOpenDetail] = useState(false);
    const [openCodeForKitFast, setOpenCodeForKitFast] = useState(false);
    const [selectedCodeForKit, setSelectedCodeForKit] = useState({
        _idCourse: '',
        detailCourse: {},
        numberOfUses: 1,
        numberOfCode: 0,
        status: true,
    });
    const [listCodeForKit, setListCodeForKit] = useState([]);
    const [keyWords, setKeyWords] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [filterStatusSelected, setFilterStatusSelected] = useState('');
    const [rowCountState, setRowCountState] = useState(1);

    const getAllCodeForKit = async (q = '', pageSize, pageNum) => {
        try {
            setIsLoading(true);
            const codeForKit = await codeForKitService.getAllCodeForKit(
                q,
                pageSize,
                pageNum,
            );
            setListCodeForKit(codeForKit.data.data.data);
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmitFormSearch = async (e) => {
        console.log(e.target.value);
        getAllCodeForKit(e.target.value);
    };
    const handleDeleteVoucher = async (id) => {
        const res = await codeForKitService.deleteCodeForKit(id);
        if (res.data.code === 'codeForKit/delete-success') {
            dispatch(
                openAlert({
                    message: 'Xoá voucher thành công',
                    severity: 'success',
                }),
            );
            getAllCodeForKit();
        } else {
            dispatch(
                openAlert({
                    message: 'Xoá voucher thất bại',
                    severity: 'error',
                }),
            );
        }
        console.log(res);
    };

    useEffect(() => {
        getAllCodeForKit();
    }, [
        openDetail,
        openCodeForKitFast,
        paginationModel.page,
        paginationModel.pageSize,
    ]);

    const columns = [
        {
            field: 'codeForKit',
            headerName: 'Mã khuyến kit',
            flex: 0.5,
        },
        {
            field: 'detailCourse',
            headerName: 'Khóa học',
            flex: 0.5,
            renderCell: (props) => {
                const { value } = props;
                return <> {value.title} </>;
            },
            valueFormatter: (props) => `${props?.value?.title}`,
        },
        {
            field: 'status',
            headerName: 'Trạng thái',
            flex: 0.4,
            renderCell: (params) => {
                const { value } = params;
                return (
                    <Chip
                        label={value ? 'Chưa dùng' : 'Đã dùng'}
                        color={value ? 'success' : 'error'}
                    />
                );
            },
            valueFormatter: (params) =>
                params?.value ? 'Chưa dùng' : 'Đã dùng',
        },
        {
            field: 'createdAt',
            headerName: 'Ngày tạo',
            flex: 0.4,
            renderCell: (params) => {
                const date = new Date(params.value);
                return `${String(date.getDate()).padStart(2, '0')}-${String(
                    date.getMonth() + 1,
                ).padStart(2, '0')}-${date.getFullYear()}`;
            },
        },
        {
            headerName: 'Thao tác',
            flex: 0.5,
            renderCell: (props) => (
                <strong>
                    <Button
                        variant='contained'
                        size='small'
                        color='warning'
                        onClick={() => {
                            console.log('DELETE: ', props.row._id);
                            handleDeleteVoucher(props.row._id);
                        }}
                        sx={{
                            marginLeft: '5px',
                        }}
                    >
                        Xóa
                    </Button>
                </strong>
            ),
            disableExport: true,
        },
    ];

    const handleClose = () => {
        setOpenDetail(false);
    };

    return (
        <Box m='1.5rem 2.5rem'>
            <Box m='0rem 0.5rem 0.5rem 0.5rem' sx={{ display: 'flex' }}>
                <FlexBetween
                    backgroundColor={theme.palette.background.alt}
                    borderRadius='9px'
                    gap='3rem'
                    p='0.1rem 1.5rem'
                    width='300px'
                >
                    <form onSubmit={handleSubmitFormSearch}>
                        <InputBase
                            placeholder='Nhập mã khuyến mãi để tìm kiếm'
                            value={keyWords}
                            onChange={(e) => {
                                handleSubmitFormSearch(e);
                                setKeyWords(e.target.value);
                            }}
                        />
                    </form>
                    {/* <DropDownFilter /> */}
                    {/* <DropDownFilter
                        onChange={setFilterStatusSelected}
                        options={LIST_OPTIONS_STATUS}
                        optionSelected={filterStatusSelected}
                        title={'Trạng thái'}
                        key={'key'}
                        valueKey={'valueKey'}
                        titleOption={'titleOption'}
                    /> */}
                </FlexBetween>
                <FlexBetween
                    sx={{
                        marginLeft: '20px',
                    }}
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='success'
                        onClick={() => {
                            setSelectedCodeForKit({
                                _idCourse: '',
                                detailCourse: {},
                                numberOfUses: 1,
                                numberOfCode: 0,
                                status: true,
                            });
                            setOpenDetail(true);
                        }}
                    >
                        Thêm mã
                    </Button>
                </FlexBetween>
            </Box>
            <Box height='80vh'>
                <NewCodeForKit
                    open={openDetail}
                    codeForKitProp={selectedCodeForKit}
                    handleClose={handleClose}
                />

                <DataGrid
                    getRowId={(row) => row._id}
                    loading={isLoading}
                    rows={listCodeForKit || []}
                    rowCount={rowCountState}
                    columns={columns}
                    pageSizeOptions={[10, 20, 25]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    paginationMode='server'
                />
            </Box>
        </Box>
    );
};

export default CodeForKit;
