import React from 'react';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material';
import {
    SettingsOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    Groups2Outlined,
    ReceiptLongOutlined,
    PublicOutlined,
    PointOfSaleOutlined,
    TodayOutlined,
    CalendarMonthOutlined,
    AdminPanelSettingsOutlined,
    TrendingUpOutlined,
    PieChartOutlined,
    DescriptionIcon,
} from '@mui/icons-material';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import EditIcon from '@mui/icons-material/Edit';
import AlarmIcon from '@mui/icons-material/Alarm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FlexBetween from './FlexBetween';
// import profileImage from 'assets/profile.jpeg';

const navItems = [
    {
        text: 'Dashboard',
        url: 'dashboard',
        icon: <HomeOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý doanh thu',
        url: 'statistical',
        icon: <TrendingUpOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý sản phẩm',
        url: 'products',
        icon: <ShoppingCartOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý người dùng',
        url: 'users',
        icon: <Groups2Outlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý đơn hàng',
        url: 'orders',
        icon: <ReceiptLongOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý danh mục sản phẩm',
        url: 'category',
        icon: <PublicOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý khuyến mãi',
        url: 'voucher',
        icon: <PointOfSaleOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý slider',
        url: 'sliders',
        icon: <Groups2Outlined />,
        requireAdmin: true,
    },

    {
        text: 'Quản lý mã kit',
        url: 'codeForKit',
        icon: <PieChartOutlined />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý chương trình flash sale',
        url: 'flashSale',
        icon: <AlarmIcon />,
        requireAdmin: true,
    },
    {
        text: 'Quản lý danh mục bài viết',
        url: 'category-post',
        icon: <EditLocationAltIcon />,
        requireAdmin: false,
    },
    {
        text: 'Quản lý bài viết',
        url: 'posts',
        icon: <EditIcon />,
        requireAdmin: false,
    },
    {
        text: 'Video giới thiệu blog',
        url: 'video-intro-blog',
        icon: <SettingsOutlined />,
        requireAdmin: false,
    },
    // {
    //     text: 'Quản lý slider',
    //     url: 'sliders',
    //     icon: <SettingsOutlined />,
    // },

    // {
    //   text: "Quản lý bài viêt",
    //   url: 'customers',
    //   icon: <Groups2Outlined />
    // },
    // {
    //   text: "Transactions",
    //   icon: <ReceiptLongOutlined />,
    // },
    // {
    //   text: "Geography",
    //   icon: <PublicOutlined />,
    // },
    // {
    //   text: "Sales",
    //   icon: null,
    // },
    // {
    //   text: "Overview",
    //   icon: <PointOfSaleOutlined />,
    // },
    // {
    //   text: "Daily",
    //   icon: <TodayOutlined />,
    // },
    // {
    //   text: "Monthly",
    //   icon: <CalendarMonthOutlined />,
    // },
    // {
    //   text: "Breakdown",
    //   icon: <PieChartOutlined />,
    // },
    // {
    //   text: "Management",
    //   icon: null,
    // },
    // {
    //   text: "Admin",
    //   icon: <AdminPanelSettingsOutlined />,
    // },
];

const Sidebar = ({
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile,
}) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);
    const user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null;
    const isAdmin =
        user?.roles.includes('admin') || user?.roles.includes('root')
            ? true
            : false;
    const listNav = isAdmin
        ? navItems
        : navItems.filter((nav) => !nav.requireAdmin);
    console.log(pathname);

    return (
        <Box component='nav'>
            {isSidebarOpen && (
                <Drawer
                    open={isSidebarOpen}
                    onClose={() => setIsSidebarOpen(false)}
                    variant='persistent'
                    anchor='left'
                    sx={{
                        width: drawerWidth,
                        '& .MuiDrawer-paper': {
                            color: theme.palette.secondary[200],
                            backgroundColor: theme.palette.background.alt,
                            boxSixing: 'border-box',
                            borderWidth: isNonMobile ? 0 : '2px',
                            width: drawerWidth,
                        },
                    }}
                >
                    <Box width='100%'>
                        <Box m='1.5rem 2rem 2rem 3rem'>
                            <FlexBetween color={theme.palette.secondary.main}>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    gap='0.5rem'
                                >
                                    <Typography variant='h4' fontWeight='bold'>
                                        Dev-iot
                                    </Typography>
                                </Box>
                                {!isNonMobile && (
                                    <IconButton
                                        onClick={() =>
                                            setIsSidebarOpen(!isSidebarOpen)
                                        }
                                    >
                                        <ChevronLeft />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        </Box>
                        <List>
                            {listNav.map(({ text, icon, url }) => {
                                if (!icon) {
                                    return (
                                        <Typography
                                            key={text}
                                            sx={{ m: '2.25rem 0 1rem 3rem' }}
                                        >
                                            {text}
                                        </Typography>
                                    );
                                }
                                const lcText = text.toLowerCase();

                                return (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/${url}`);
                                                setActive(url);
                                            }}
                                            sx={{
                                                backgroundColor:
                                                    active === url
                                                        ? '#ffbe76'
                                                        : 'transparent',
                                                color:
                                                    active === url
                                                        ? '#191F45'
                                                        : '#black',
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    ml: '2rem',
                                                    color:
                                                        active === lcText
                                                            ? '#191F45'
                                                            : '#a6a9be',
                                                }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                            <ListItemText primary={text} />
                                            {active === lcText && (
                                                <ChevronRightOutlined
                                                    sx={{ ml: 'auto' }}
                                                />
                                            )}
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                    {/* <Box position="absolute" bottom="2rem">
            <Divider />
            <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="40px"
                width="40px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.8rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <SettingsOutlined
                sx={{
                  color: theme.palette.secondary[300],
                  fontSize: "25px ",
                }}
              />
            </FlexBetween>
          </Box> */}
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
