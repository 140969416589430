import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckBox(props) {
    const { label, checked, onChange } = props;
    return (
        <FormControlLabel
            control={<Checkbox />}
            label={label}
            checked={checked}
            onClick={onChange}
        />
    );
}

export default CheckBox;
