import { Box, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import FindProductByCategory from '../../../components/FindProductByCategory';
import postService from '../../../services/post/post.service';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import uploadFileService from '../../../services/upload/upload.service';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import Editor from 'components/Editor';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SwitchStatus from 'components/SwitchStatus';
import CategoriesPost from '../../../components/CategoriesPost';
import { useNavigate } from 'react-router-dom';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function PostsNew(props) {
    const { open, handleClose, post, listCategoriesPost } = props;
    const [detailPost, setDetailPost] = useState();
    const [thumbnailSelected, setThumbnailSelected] = useState();
    const [urlFilePreview, setUrlFilePreview] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        console.log();
        if (post?.slug) {
            postService.getDetailPost(post.slug).then((res) => {
                console.log('get detail data: ', res.data.data);
                setDetailPost(res.data.data);
            });
        } else {
            setDetailPost(post);
        }
        setThumbnailSelected();
    }, [post]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setThumbnailSelected(undefined);
            return;
        }
        setThumbnailSelected(e.target.files[0]);
    };

    useEffect(() => {
        if (!thumbnailSelected) {
            setUrlFilePreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(thumbnailSelected);
        setUrlFilePreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnailSelected]);

    const handleOnChangeDetailPost = (e, type) => {
        switch (type) {
            case 'title':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        title: e.target.value,
                    };
                });
                break;

            case 'view':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        view: e.target.value,
                    };
                });

                break;

            case 'rating':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        rating: e.target.value,
                    };
                });
                break;

            case 'urlVideoIntro':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        urlVideoIntro: e.target.value,
                    };
                });
                break;

            case 'isPublish':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        isPublish: e,
                    };
                });
                break;

            case 'content':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        content: e,
                    };
                });
                break;

            case 'sortOrder':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        sortOrder: e.target.value,
                    };
                });
                break;

            case 'thumbnail':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        thumbnail: e,
                    };
                });
                break;

            case 'category':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        category: e,
                    };
                });
                break;

            case 'description':
                setDetailPost((prePost) => {
                    return {
                        ...prePost,
                        description: e.target.value,
                    };
                });
                break;
            default:
                break;
        }
    };

    const handleSubmitProduct = async () => {
        console.log('Submit', detailPost);
        let urlImage = null;
        let newPost = detailPost;

        try {
            if (thumbnailSelected) {
                const formData = new FormData();
                formData.append('file', thumbnailSelected);
                console.log(thumbnailSelected);
                urlImage = await uploadFileService.uploadFile(formData);
                newPost.thumbnail = urlImage.data.data.path;
                setUrlFilePreview(undefined);
                setThumbnailSelected(undefined);
            }

            if (!detailPost._id) {
                const data = await postService.createPost(newPost);
                if (
                    data.data.statusCode === 200 &&
                    data.data.code === 'post/create-post-success'
                ) {
                    dispatch(
                        openAlert({
                            message: 'Thêm bài viết thành công',
                            severity: 'success',
                        }),
                    );
                } else {
                    dispatch(
                        openAlert({
                            message: 'Thêm bài viết thất bại',
                            severity: 'error',
                        }),
                    );
                }
            } else {
                const data = await postService.updatePost(newPost);
                console.log('Update post', data);
                if (
                    data.data.statusCode === 200 &&
                    data.data.code === 'post/update-post-success'
                ) {
                    dispatch(
                        openAlert({
                            message: 'Cập nhật bài viết thành công',
                            severity: 'success',
                        }),
                    );
                } else {
                    dispatch(
                        openAlert({
                            message: 'Cập nhật bài viết thất bại',
                            severity: 'error',
                        }),
                    );
                }
            }
            handleClose();
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogContent>
                <DialogTitle>
                    <h3>
                        {detailPost?._id
                            ? `Chỉnh sửa bài viết`
                            : `Thêm bài viết`}
                    </h3>
                </DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <TextField
                                label='Tiêu đề bài viết'
                                type='text'
                                size='small'
                                value={detailPost?.title || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailPost(e, 'title')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Số sao đánh giá trung bình'
                                type='number'
                                size='small'
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                                onChange={(e) =>
                                    handleOnChangeDetailPost(e, 'rating')
                                }
                                value={detailPost?.rating || ''}
                            />

                            <TextField
                                label='Số lượt xem'
                                type='number'
                                size='small'
                                value={detailPost?.view || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailPost(e, 'view')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Link video hướng dẫn'
                                type='text'
                                size='small'
                                value={detailPost?.urlVideoIntro || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailPost(e, 'urlVideoIntro')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <TextField
                                label='Vị trí hiển thi'
                                type='number'
                                size='small'
                                value={detailPost?.sortOrder || ''}
                                onChange={(e) =>
                                    handleOnChangeDetailPost(e, 'sortOrder')
                                }
                                sx={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                            />

                            <Box
                                sx={{
                                    border: '1px dotted black',
                                    padding: '10px',
                                    height: '25vh',
                                    marginTop: '10px',
                                }}
                            >
                                {(detailPost?.thumbnail ||
                                    thumbnailSelected) && (
                                    <img
                                        src={
                                            urlFilePreview ||
                                            detailPost.thumbnail
                                        }
                                        alt=''
                                        style={{
                                            height: '80%',
                                            width: '40%',
                                        }}
                                    />
                                )}
                                <input type='file' onChange={onSelectFile} />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <Box>
                                <CategoriesPost
                                    label='Danh mục cho bài viết'
                                    listCategories={listCategoriesPost}
                                    listCategoriesSelected={
                                        detailPost?.category || []
                                    }
                                    onChange={handleOnChangeDetailPost}
                                />
                            </Box>
                            <Box>
                                {/* <h3>Mô tả ngắn</h3> */}
                                <TextField
                                    label='Mô tả ngắn'
                                    multiline
                                    rows={10}
                                    value={detailPost?.description || ''}
                                    onChange={(e) =>
                                        handleOnChangeDetailPost(
                                            e,
                                            'description',
                                        )
                                    }
                                    sx={{
                                        width: '100%',
                                        marginTop: '10px',
                                    }}
                                />
                                {/* <Editor
                                    onChange={(value) => {
                                        handleOnChangeDetailPost(
                                            value,
                                            'description',
                                        );
                                    }}
                                    value={detailPost?.description || ''}
                                    name='description'
                                /> */}
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Box>
                            <h3>Nội dung bài viết</h3>
                            <Editor
                                onChange={(value) => {
                                    handleOnChangeDetailPost(value, 'content');
                                }}
                                value={detailPost?.content}
                                name='content'
                            />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <SwitchStatus
                    label='Status'
                    checked={detailPost?.isPublish}
                    onChange={(e) => {
                        handleOnChangeDetailPost(e.target.checked, 'isPublish');
                    }}
                />
                <Button
                    onClick={() => {
                        handleSubmitProduct();
                    }}
                    sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                    }}
                    size='small'
                    variant='contained'
                    color='success'
                >
                    {detailPost?._id ? `Chỉnh sửa bài viết` : `Thêm bài viết`}
                </Button>
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Hủy
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PostsNew;
