import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import Chip from '@mui/material/Chip';
import { DataGrid } from "@mui/x-data-grid";
import categoryService from "services/category/category.service";
import productService from "services/product/product.service";
import FindProductByCategory from "components/FindProductByCategory";
import { useDispatch } from "react-redux";
import { openAlert } from 'slices/alert/alertSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const renderStatusAccount = (props) =>{
  const {value} = props
  return <>
  <Chip label={value ? "Hoạt động" : "Khóa"} color={value ? "success" : "error"} />
  </>
}
  const ListCourses = (props) => {
  const {open, handleClose, setDetailCodeForKit} = props;
  const [categorySelected, setCategorySelected] = useState('');
  const [listCategories, setListCategories] =  useState([]);
  const [products, setProducts] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0
  });

  const dispatch = useDispatch();

  const getProductByCategory = (type)=>{
    productService.getAllProducts(type)
      .then((res)=>{
        console.log('Product ',res.data.data.products);
      setProducts(res.data.data.products);
    })
  }

 
  useEffect(()=>{
    categoryService.getAllCategory()
    .then((res)=>{
      setListCategories(res.data.data);
      setCategorySelected(res.data.data[0].slug);
    })
  },[])

  useEffect(()=>{
   if(categorySelected)
   {
    console.log('categorySelected: ',categorySelected)
    getProductByCategory(categorySelected);
   }
  },[categorySelected])


  const columns = [
    {
      field: "title",
      headerName: "Tên sản phẩmxxx",
      flex: 1,
    },
    {
      field: "originalPrice",
      headerName: "Gía bánxxx",
      flex: 0.5,
    },
    {
      field: "createdBy",
      headerName: "Người Tạo",
      flex: 0.4,
    },
    {
      field: "isPublish",
      headerName: "Trạng thái",
      flex: 0.4,
      renderCell: renderStatusAccount
    },

    // 
    {
      // field: "roles",
      headerName: "Thao tác",
      flex: 0.5,
      renderCell: (props) => {
        console.log("Hoang 999999",props.row)
        const course = props.row;
        return (
          <strong>
            <Button
              variant="contained"
              size="small"
              onClick={()=>{
                setDetailCodeForKit({_id: course._id, title: course.title,  typeProduct: course.typeProduct , slug: course.slug })
                handleClose()
              }}
            >
              Chọn
            </Button>
          </strong>
        )
      },
   
    },
  ];

  const handleChange = (value) => {
    setCategorySelected(value);
  };

  const handleDeleteProduct= async (_id)=>{
    const res = await productService.deleteProduct(_id);
    console.log(res)
    if(res?.data?.code === 'product/delete-product-successfully')
    {
      getProductByCategory(categorySelected);
      dispatch(
        openAlert({
          message: 'Xóa sản phẩm thành công',
          severity: 'success'
      }));
    }
    else{
      dispatch(
        openAlert({
          message: 'Xóa sản phẩm thất bại',
          severity: 'error'
      }));
    }
  }
  
  return (
    <Dialog 
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xl'}
        >
            <DialogContent 
            >
                <DialogTitle>
                  Chọn khóa học
                </DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                            width: '100%',
                            margin: '5px',
                            boxSizing: 'content-box'
                            }}
                        >
                          <Box m="1.5rem 2.5rem">
                            <Box>
                              <FindProductByCategory
                              sx={{ m: 1, minWidth: 200, maxHeight: 90, padding: '5px' }}
                              handleChange={handleChange}
                              categorySelected={categorySelected}
                              listCategories={listCategories}
                              />
                              <Button
                              sx={{ m: 1, minWidth: 120, maxHeight: 90 }} size="medium" variant="contained" color="success">
                                Thêm sản phẩm
                              </Button>
                            </Box>
                            <Box
                              height="75vh"
                            >
                              <DataGrid
                              columns={columns}
                              rows={products}
                              getRowId={(row) => row._id}
                              pageSizeOptions={[20, 25]}
                              paginationModel={paginationModel}
                              onPaginationModelChange={setPaginationModel}
                              />
                          </Box>
                        </Box>

                        </Box>
                    </Box>
                   
                    
                  
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    size="small" variant="contained" color="success"
                    >Đóng
                </Button>
            </DialogActions>
        </Dialog>
   
  );
};

export default ListCourses;
