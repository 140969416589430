import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openAlert } from 'slices/alert/alertSlice';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import flashSaleService from 'services/flashSale/flashSale.service';
import ListCourses from './ListCourses';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
// dayjs.extend(utc);
// dayjs.extend(timezone);

function NewFlashSale(props) {
    const { open, handleClose, flashSaleProp } = props;
    const [openListCourses, setOpenListCourse] = useState(false);
    const [detailFlashSale, setDetailFlashSale] = useState({
        title: '',
        listProducts: [],
        isPublic: true,
        startDate: '',
        endDate: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setDetailFlashSale(flashSaleProp);
    }, [flashSaleProp]);

    const handleSubmitFlashSale = async () => {
        try {
            await flashSaleService.createFlashSale(detailFlashSale);
            dispatch(
                openAlert({
                    message: 'Tạo chương trình flash sale thành công',
                    severity: 'success',
                }),
            );
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.code === 'auth/refresh-token-invalid') {
                dispatch(
                    openAlert({
                        message:
                            'Phiên đăng nhập hết hạn, vui lòng đăng nhập lại',
                        severity: 'error',
                    }),
                );
                localStorage.removeItem('user');
                navigate('/login');
            }
        }
    };

    const onChangeDetailFlashSale = (value, type) => {
        switch (type) {
            case 'title':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        title: value,
                    };
                });
                break;

            case 'listProducts':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        listProducts: value,
                    };
                });
                break;

            case 'startDate':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        startDate: value,
                    };
                });
                break;

            case 'endDate':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        endDate: value,
                    };
                });
                break;

            case 'isPublic':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        isPublic: value,
                    };
                });
                break;

            case 'priceSale':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        listProducts: preDetailFlashSale.listProducts?.map(
                            (item) => {
                                return item._id === value._id
                                    ? {
                                          ...item,
                                          priceSale: value?.priceSale,
                                          percentage:
                                              (
                                                  value?.priceSale /
                                                  item?.originalPrice
                                              ).toFixed(2) * 100,
                                      }
                                    : item;
                            },
                        ),
                    };
                });
                break;

            case 'percentage':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        listProducts: preDetailFlashSale.listProducts?.map(
                            (item) => {
                                return item._id === value._id
                                    ? {
                                          ...item,
                                          priceSale:
                                              item?.originalPrice *
                                              (value.percentage / 100).toFixed(
                                                  2,
                                              ),
                                          percentage: value.percentage,
                                      }
                                    : item;
                            },
                        ),
                    };
                });
                break;

            case 'deleteProduct':
                setDetailFlashSale((preDetailFlashSale) => {
                    return {
                        ...preDetailFlashSale,
                        listProducts: preDetailFlashSale?.listProducts?.filter(
                            (product) => product._id !== value,
                        ),
                    };
                });
                break;

            default:
                break;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogContent>
                <DialogTitle>Thông tin mã</DialogTitle>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                margin: '5px',
                                boxSizing: 'content-box',
                            }}
                        >
                            <ListCourses
                                open={openListCourses}
                                handleClose={() => {
                                    setOpenListCourse(false);
                                }}
                                setDetailFlashSale={(detailProducts) => {
                                    onChangeDetailFlashSale(
                                        detailProducts,
                                        'listProducts',
                                    );
                                }}
                                listProducts={detailFlashSale?.listProducts}
                            />
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <TextField
                                    label='Chương trình flash sale'
                                    type='text'
                                    size='medium'
                                    value={detailFlashSale?.title}
                                    onChange={(e) => {
                                        onChangeDetailFlashSale(
                                            e.target.value,
                                            'title',
                                        );
                                    }}
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        value={dayjs(
                                            detailFlashSale?.startDate,
                                        )}
                                        label='Ngày bắt đầu'
                                        onChange={(newValue) => {
                                            const date = new Date(newValue);
                                            onChangeDetailFlashSale(
                                                date.toISOString(),
                                                'startDate',
                                            );
                                            // onChangeDetailVouchers(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,'startDate');
                                        }}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DateTimePicker
                                        sx={{ marginLeft: '15px' }}
                                        value={dayjs(detailFlashSale?.endDate)}
                                        label='Ngày kết thúc'
                                        // timezone="America/New_York"
                                        onChange={(newValue) => {
                                            const date = new Date(newValue);
                                            console.log(date.toISOString());
                                            onChangeDetailFlashSale(
                                                date.toISOString(),
                                                'endDate',
                                            );
                                            // onChangeDetailVouchers(`${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,'endDate');
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '10px',
                                    display: 'flex',
                                }}
                            >
                                <caption>
                                    Các sản phẩm trong chương trình flash sale
                                </caption>
                                {detailFlashSale?.listProducts.length > 0 && (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label='caption table'
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Tên sản phẩm
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        Gía bán gốc
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Gía bán sau sale
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        Phần trăm sale
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {detailFlashSale?.listProducts.map(
                                                    (product) => (
                                                        <TableRow
                                                            key={product._id}
                                                        >
                                                            <TableCell
                                                                component='th'
                                                                scope='row'
                                                            >
                                                                {product.title}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {
                                                                    product.originalPrice
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <TextField
                                                                    value={
                                                                        product.originalPrice -
                                                                        product.priceSale
                                                                    }
                                                                    sx={{
                                                                        width: '50%',
                                                                    }}
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        onChangeDetailFlashSale(
                                                                            {
                                                                                _id: product._id,
                                                                                priceSale:
                                                                                    product.originalPrice -
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            },
                                                                            'priceSale',
                                                                        );
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <FormControl
                                                                    sx={{
                                                                        m: 1,
                                                                        width: '50%',
                                                                    }}
                                                                    variant='outlined'
                                                                >
                                                                    <OutlinedInput
                                                                        id='outlined-adornment-weight'
                                                                        endAdornment={
                                                                            <InputAdornment position='end'>
                                                                                %
                                                                            </InputAdornment>
                                                                        }
                                                                        aria-describedby='outlined-weight-helper-text'
                                                                        inputProps={{
                                                                            'aria-label':
                                                                                'weight',
                                                                        }}
                                                                        type='number'
                                                                        value={
                                                                            product.percentage
                                                                        }
                                                                        onChange={(
                                                                            e,
                                                                        ) => {
                                                                            onChangeDetailFlashSale(
                                                                                {
                                                                                    _id: product._id,
                                                                                    percentage:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                },
                                                                                'percentage',
                                                                            );
                                                                        }}
                                                                    />
                                                                    {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText> */}
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                <Button
                                                                    onClick={() => {
                                                                        onChangeDetailFlashSale(
                                                                            product._id,
                                                                            'deleteProduct',
                                                                        );
                                                                    }}
                                                                    size='small'
                                                                    variant='contained'
                                                                    color='success'
                                                                    // onClick=(()=>)
                                                                >
                                                                    Xóa
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ),
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {!detailFlashSale?._id && (
                    <>
                        <Button
                            onClick={() => {
                                setOpenListCourse(true);
                            }}
                            size='small'
                            variant='contained'
                            color='warning'
                        >
                            Chọn sản phẩm
                        </Button>
                        <Button
                            onClick={handleSubmitFlashSale}
                            sx={{ marginRight: '5px' }}
                            size='small'
                            variant='contained'
                            color='success'
                            disabled={
                                !detailFlashSale?.listProducts?.length ||
                                !detailFlashSale.startDate ||
                                !detailFlashSale.endDate
                            }
                        >
                            Thêm chương trình flash sale
                        </Button>
                    </>
                )}
                <Button
                    onClick={handleClose}
                    size='small'
                    variant='contained'
                    color='warning'
                >
                    Đóng
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewFlashSale;
