import instanceAxios from '../../helper/axios.config';

const uploadFile = (formData) => {
    return instanceAxios.post('/upload', formData,
    {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

const uploadFileService = {
    uploadFile
}

export default uploadFileService
