import instance from '../../helper/axios.config';


const login = (email, password) => {
    return instance.post("/auth", {
            email,
            password,
        })
        .then((response) => {
            console.log(response);
            return response;
        });
};

const refreshToken = (refreshToken)=>{
    return instance.post('/instance',
        {
            refreshToken
        }
    )
}

const authService = {
    // register,
    login,
    refreshToken
    // logout,
};

export default authService;