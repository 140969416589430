import { ResponsiveBar } from '@nivo/bar';
function BarChart({ data, keys, indexBy, colors, tooltip }) {
    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            indexBy={indexBy}
            margin={{
                top: 50,
                right: 100,
                bottom: 70,
                left: 100,
            }}
            padding={0.7}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={colors}
            colorBy='indexValue'
            barWidth={20}
            borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            // axisBottom={{
            //     tickSize: 5,
            //     tickPadding: 12,
            //     tickRotation: 0,
            //     legend: 'Tháng',
            //     legendPosition: 'middle',
            //     legendOffset: 40,
            //     truncateTickAt: 0,
            // }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: 'Doanh thu',
                legendPosition: 'middle',
                legendOffset: -80,
                truncateTickAt: 0,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
            }}
            tooltip={tooltip}
            // legends={[
            //     {
            //         dataFrom: 'keys',
            //         anchor: 'bottom-right',
            //         direction: 'column',
            //         justify: false,
            //         translateX: 120,
            //         translateY: 0,
            //         itemsSpacing: 2,
            //         itemWidth: 100,
            //         itemHeight: 20,
            //         itemDirection: 'left-to-right',
            //         itemOpacity: 0.85,
            //         symbolSize: 20,
            //         effects: [
            //             {
            //                 on: 'hover',
            //                 style: {
            //                     itemOpacity: 1,
            //                 },
            //             },
            //         ],
            //     },
            // ]}
            // role='application'
            // ariaLabel='Nivo bar chart demo'
            // barAriaLabel={(e) =>
            //     e.id +
            //     ': ' +
            //     e.formattedValue +
            //     ' in country727727: ' +
            //     e.indexValue
            // }
        />
    );
}

export default BarChart;
